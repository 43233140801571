import { useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../contexts/auth-context';
import Spinner from '../../shared/Spinner';
import { useRoutes } from '../../../hooks/useRoutes';

const Logout = () => {
  const history = useHistory();
  const location = useLocation();
  const { logout } = useContext(AuthContext);
  const { routesTFunction } = useRoutes();

  useEffect(() => {
    logout()
      .then(() => {
        history.push(`${routesTFunction('redirectPaths./login')}${location.search}`);
      })
      .catch((err) => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ height: '100vh' }}>
      <Spinner />
    </div>
  );
};

export default Logout;
