import { useCallback, useEffect, useRef, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import { TFunction } from 'react-i18next';
import apiService from '../services/api-service';
import { isNotUndefined } from '../utils';

type FetchMethods = 'GET';
type ApiMethods = 'PUT' | 'POST' | 'FILE' | 'DELETE' | 'FORM_DATA' | 'PATCH';
type Method = FetchMethods | ApiMethods;

export interface QueryOptions {
  initialValues?: {
    loading?: boolean;
  };
  fallbackErrorMessage?: (t: TFunction<'errors'>) => string;
}
export function useQuery<TRequest = any, TResponse = any>(url: string, method: Method = 'GET', options?: QueryOptions) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(
    options && options.initialValues && isNotUndefined(options.initialValues.loading)
      ? options.initialValues.loading
      : false
  );
  const [response, setResponse] = useState<TResponse>();

  const mounted = useRef(true);

  useEffect(() => {
    if (!apiService.endpoint) {
      throw new Error('apiService endpoint is not defined');
    }
  }, []);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const addResponse = (res: TResponse) => {
    if (mounted.current) {
      setResponse(res);
      setLoading(false);
    }
  };

  const addError = (err: string) => {
    if (mounted.current) {
      setError(err);
      setLoading(false);
    }
  };

  const makeRequest = useCallback(
    async (newUrl?: string, data?: TRequest) => {
      try {
        setLoading(true);
        setError('');
        let res;
        if (method === 'GET') {
          res = await apiService.get(
            newUrl || url,
            data as Omit<AxiosRequestConfig, 'headers' | 'method' | 'url'>,
            options?.fallbackErrorMessage
          );
        }
        if (method === 'FORM_DATA') {
          const { file, fileField, formdata } = data as any;
          res = await apiService.upload(
            newUrl || url,
            file,
            fileField,
            formdata,
            'POST',
            options?.fallbackErrorMessage
          );
        }
        if (res) {
          addResponse(res);
          return res as TResponse;
        }
        if (!res && !error) {
          if (mounted.current) {
            setLoading(false);
          }
        }
      } catch (err) {
        addError(err as string);
        return Promise.reject(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [method, url]
  );

  return {
    response,
    loading,
    error,
    makeRequest
  };
}
/**
 * A cache object which uses key-value pair to cache the result of GET requests
 * to make the app feel a lot smoother. The key for this cache key would be the URL of the GET request
 * and the value would be the data that is returned from making that request.
 */
const cache = {};

// Exporting a function to clear cache rather than the cache itself
// to make sure the cache isn't updated elsewhere in the app directly
export const clearCache = () => {
  Object.keys(cache).forEach((cacheKey) => {
    delete cache[cacheKey];
  });
};

export interface GetQueryOptions extends Omit<QueryOptions, 'initialValues'> {
  shouldFetch?: boolean;
}

/**
 * A hook to manage mutations(Any non GET requests made to the backend)
 * The reason for this to be separate from `useQuery` is mutation URL of the request wouldn't always
 * be known before hand for ex updating a subscription id where subsription is not known till another request is made
 * @param method takes in the type of request to make i.e.'PUT' | 'POST' | 'FILE' | 'DELETE' | 'FORM_DATA' | 'PATCH';
 * look more into makeRequest function to know what those methods would perform
 */
export function useMutation<TRequest = any, TResponse = any>(method: ApiMethods = 'POST') {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<TResponse>();

  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const addResponse = (res: TResponse) => {
    if (mounted.current) {
      setResponse(res);
      setLoading(false);
    }
  };

  const addError = (err: string) => {
    if (mounted.current) {
      setError(err);
      setLoading(false);
    }
  };

  const makeRequest = useCallback(
    async (url: string, data?: TRequest, headers?: AxiosRequestConfig['headers']) => {
      try {
        setLoading(true);
        setError('');
        let res;
        if (method === 'POST') {
          res = await apiService.post(url, data, headers);
        }
        if (method === 'PUT') {
          res = await apiService.put(url, data, headers);
        }
        if (method === 'FORM_DATA') {
          const { file, fileField, formdata } = data as any;
          res = await apiService.upload(url, file, fileField, formdata);
        }
        if (res) {
          addResponse(res);
          return res as TResponse;
        }

        if (!res && !error) {
          if (mounted.current) {
            setLoading(false);
          }
        }
      } catch (error) {
        addError(error);
        return Promise.reject(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [method]
  );

  return {
    response,
    loading,
    error,
    makeRequest
  };
}

export default useQuery;
