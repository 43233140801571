import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { AuthContextType } from '../types/common/auth-context-types';
import { isDev, isNotUndefined, loggedIn } from '../utils';
import { LOCALSTORAGE, PARTNERED_INSTITUTIONS } from '../utils/constants';
import config from '../config';
import apiService, { ApiService } from '../services/api-service';
import { clearCache } from '../hooks/useQuery';

const AuthContext = React.createContext<AuthContextType>({
  institution: undefined,
  portalEnabled: false,
  isPortalPingLoading: true,
  signup: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  login: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  logout: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  isLoggedIn: false,
  getPortalAccessForLoggedInUser: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  verifyEmailRequest: () => {
    throw new Error('The component is not wrapped inside AuthProvider');
  },
  refreshLoggedInStatus: () => {
    throw new Error('The component is not wrapped inside AuthProvider');
  },
  resetPassword: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  validatePasswordReset: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  updatePassword: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  }
});

interface AuthResult {
  access_token?: string;
  public_portal_access_token?: string;
}

type AuthProviderProps = React.PropsWithChildren<Record<string, unknown>>;

export interface VerifyEmailResponse {
  access_token: string;
}

export interface VerifyEmailData {
  verifyEmailCode: string;
}

export interface VerifyEmailResult {
  access_token: string;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const authApiEndpoint = config.AUTH_ENDPOINT;
  const apiEndpoint = config.API_ENDPOINT;

  const [institution, setInstitution] = useState<keyof typeof PARTNERED_INSTITUTIONS>();
  const [portalEnabled, setPortalEnabled] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn());

  const location = useLocation();
  const queryClient = useQueryClient();

  const authApiService = new ApiService(`${authApiEndpoint}`);

  const pingQueryKey = useMemo(() => `${institution}-ping-portal-scope-ping`, [institution]);

  const {
    status: portalPingStatus,
    error: portalPingError,
    isLoading: isPortalPingLoading
  } = useQuery(
    pingQueryKey,
    () => {
      return apiService.get(`/public/portal/${institution}/ping`);
    },
    {
      enabled: isNotUndefined(institution),
      staleTime: 0,
      cacheTime: 0
    }
  );

  const setSession = (authResult: AuthResult) => {
    const { access_token: accessToken, public_portal_access_token: publicPortalAccessToken } = authResult;

    if (accessToken) {
      localStorage.setItem(LOCALSTORAGE.publicAccessToken, accessToken);
      setIsLoggedIn(true);
    }
    if (publicPortalAccessToken) {
      localStorage.setItem(LOCALSTORAGE.publicPortalAccessToken, publicPortalAccessToken);
    }
  };

  const clearSession = () => {
    clearCache();
    queryClient.clear();
    setIsLoggedIn(false);
    localStorage.removeItem(LOCALSTORAGE.publicAccessToken);
    localStorage.removeItem(LOCALSTORAGE.publicPortalAccessToken);
  };

  const verifyEmailRequest = (data: VerifyEmailData, isRegisteredUser?: boolean): Promise<VerifyEmailResult> => {
    if (isRegisteredUser) {
      return apiService.put(
        `/public/portal/user/verify-email/${data.verifyEmailCode}`,
        {},
        {
          Authorization: `Bearer ${localStorage.getItem(LOCALSTORAGE.publicAccessToken)}`,
          'Content-Type': 'application/json'
        }
      );
    }

    return apiService.post(`/public/portal/verify-email/${data.verifyEmailCode}`, {}).then((res) => {
      if (res?.access_token) {
        setSession({ public_portal_access_token: res.access_token });
        return res;
      }
    });
  };

  const createUserRequest = (email: string, accessToken: string) => {
    return apiService.post(
      '/onboarding/create_user',
      {
        user: { email: email }
      },
      {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    );
  };

  const sendVerifyEmailRequest = ({
    accessToken,
    isRegisteredUser
  }: {
    accessToken?: string;
    isRegisteredUser?: boolean;
  }) => {
    if (isRegisteredUser && accessToken) {
      return apiService.post(
        '/public/portal/user/verify-email',
        {
          institution_name: institution
        },
        {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      );
    }

    return apiService.post('/public/portal/verify-email', {
      institution_name: institution
    });
  };

  const signUpRequest = (email: string, password: string) => {
    return authApiService.post('/signup', {
      user: {
        email: email,
        password: password
      },
      platform: 'portal'
    });
  };

  const signup = async (email: string, password: string): Promise<any> => {
    // Sign up request first to get access token to create the user then send the verification email
    return signUpRequest(email, password).then((response: { access_token: string }) => {
      if (response.access_token) {
        const { access_token: accessToken } = response;
        return createUserRequest(email, accessToken).then(() => {
          return sendVerifyEmailRequest({ accessToken: accessToken, isRegisteredUser: true }).then(() => {
            localStorage.setItem(LOCALSTORAGE.publicAccessToken, response.access_token);
          });
        });
      }
    });
  };

  const login = (username: string, password: string): Promise<any> => {
    return authApiService
      .post('/authenticate', {
        user: {
          email: username,
          password
        }
      })
      .then((res) => {
        setSession({
          access_token: res.access_token
        });
        return res;
      });
  };

  const logout = () => {
    return apiService.get('/logout').finally(() => clearSession());
  };

  const getResetPasswordUrl = () => {
    // eslint-disable-next-line quotes
    const isProduction = process.env.REACT_APP_NODE_ENV === "'production'";
    // eslint-disable-next-line quotes
    const isStaging = process.env.REACT_APP_NODE_ENV === "'staging'";
    if ((isProduction || isStaging) && institution) {
      return config.RESET_PW_REDIRECT.replace('institution', institution);
    }

    return config.RESET_PW_REDIRECT;
  };

  const resetPassword = (email: string) => {
    const redirectUrl = getResetPasswordUrl();
    return authApiService.post('/reset-password', {
      user: { email },
      redirect_url: redirectUrl
    });
  };

  const validatePasswordReset = (token: string, query: string) => {
    return authApiService.get(`/update-password/${token}${query}`);
  };

  const updatePassword = (password: string, token: string, query: string) => {
    const [, email] = query.split('=');
    return authApiService.put(`/update-password/${token}`, {
      user: { password, email }
    });
  };

  const getPortalAccessForLoggedInUser = () => {
    return apiService
      .post(
        `/public/portal/${institution}/access`,
        {},
        {
          Authorization: `Bearer ${localStorage.getItem(LOCALSTORAGE.publicAccessToken)}`,
          'Content-Type': 'application/json'
        }
      )
      .then((res: { access_token: string }) => {
        setSession({ public_portal_access_token: res.access_token });
      });
  };

  const refreshLoggedInStatus = () => {
    setIsLoggedIn(loggedIn());
  };

  useEffect(() => {
    if (portalPingStatus === 'success' && !portalPingError) {
      setPortalEnabled(true);
    } else {
      setPortalEnabled(false);
    }
  }, [portalPingStatus, portalPingError]);

  useEffect(() => {
    if (isDev) {
      if (isNotUndefined(process.env.REACT_APP_PARTNERTED_INSTITUTION)) {
        setInstitution(process.env.REACT_APP_PARTNERTED_INSTITUTION as keyof typeof PARTNERED_INSTITUTIONS);
      } else {
        throw new Error('Missing dev environment variable for partnered institution');
      }
    } else {
      window.location.host.split('.').forEach((name) => {
        const formattedName = name.toLocaleLowerCase();
        if (isNotUndefined(PARTNERED_INSTITUTIONS[formattedName])) {
          setInstitution(PARTNERED_INSTITUTIONS[formattedName]);
        }
      });
    }
  }, [apiEndpoint]);

  useEffect(() => {
    setIsLoggedIn(loggedIn);
  }, [location]);

  return (
    <AuthContext.Provider
      value={{
        institution,
        portalEnabled,
        isPortalPingLoading,
        signup,
        login,
        verifyEmailRequest,
        isLoggedIn,
        logout,
        getPortalAccessForLoggedInUser,
        refreshLoggedInStatus,
        resetPassword,
        validatePasswordReset,
        updatePassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
