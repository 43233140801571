import { ReducerActionMap } from '../utils/ActionMap';
import { Institution, InstitutionAddress } from './common/IInstitution';

export * from './common/IInstitution';
export * from './common/IInformationRequest';
export * from './common/IGenericHealthInformationResource';

export interface PersonalInformationType {
  first_name: string;
  last_name: string;
  email: string;
  insurance_number: string;
  month: string;
  day: string;
  year: string;
  dob: string;
  phone_number: string;
  street_address: string;
  city: string;
  province: string;
  postal_code: string;
  extra_line_1: string;
}

export interface Address {
  id: number;
  city: string;
  country: string;
  extra_line_1: string;
  postal_code: string;
  province: string;
  street_address: string;
  unit_number: string;
}

export interface AccessPermission {
  id: number;
  created_at: string;
  updated_at: string;
  can_read: boolean;
  can_update: boolean;
  can_delete: boolean;
  is_read: boolean;
  internal_id: number;
}

export interface Physician {
  id: number;
  first_name: string;
  last_name: string;
  created_at: Date;
  institution_addresses?: InstitutionAddress[];
  specializations: any[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum PROVIDER_TYPE {
  PHYSICIAN = 'physician',
  INSTITUTION = 'institution'
}

export interface InstitutionProvider extends Institution {
  providerType: PROVIDER_TYPE.INSTITUTION;
}

export interface PhysicianProvider extends Physician {
  providerType: PROVIDER_TYPE.PHYSICIAN;
}

export type Provider = InstitutionProvider | PhysicianProvider;

export enum CreateRequestReducerActionTypes {
  ADD_HEALTH_CARE_PROVIDER = 'ADD_HEALTH_CARE_PROVIDER',
  REMOVE_HEALTHCARE_PROVIDER = 'REMOVE_HEALTHCARE_PROVIDER',
  ADD_PHYSICIAN_TO_INSTITUTION = 'ADD_PHYSICIAN_TO_INSTITUTION',
  REMOVE_PHYSICIAN_FROM_INSTITUTION = 'REMOVE_PHYSICIAN_FROM_INSTITUTION',
  ADD_NOTES_TO_REQUEST = 'ADD_NOTES_TO_REQUEST',
  REMOVE_NOTES_FROM_REQUEST = 'REMOVE_NOTES_FROM_REQUEST',
  RESET = 'RESET'
}

export type CreateRequestReducerPayload = {
  [CreateRequestReducerActionTypes.ADD_HEALTH_CARE_PROVIDER]: {
    institution: Institution;
    address: InstitutionAddress;
    physician?: Physician;
    callback?: () => void;
  };
  [CreateRequestReducerActionTypes.REMOVE_HEALTHCARE_PROVIDER]: {
    index: number;
  };
  [CreateRequestReducerActionTypes.ADD_PHYSICIAN_TO_INSTITUTION]: {
    index: number;
    physician: Physician;
  };
  [CreateRequestReducerActionTypes.REMOVE_PHYSICIAN_FROM_INSTITUTION]: {
    index: number;
  };
  [CreateRequestReducerActionTypes.ADD_NOTES_TO_REQUEST]: {
    index: number;
    notes: string;
  };
  [CreateRequestReducerActionTypes.REMOVE_NOTES_FROM_REQUEST]: {
    index: number;
  };
  [CreateRequestReducerActionTypes.RESET]: undefined;
};

export type CreateRequestReducerActions =
  ReducerActionMap<CreateRequestReducerPayload>[keyof ReducerActionMap<CreateRequestReducerPayload>];

export type HealthCareProvider = {
  institution: Institution;
  address: InstitutionAddress;
  physician?: Physician;
  notes?: string;
};

export type CreateRequestReducerState = {
  healthCareProviders: HealthCareProvider[];
};
