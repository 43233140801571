import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import {
  CreateRequestReducerActions,
  CreateRequestReducerActionTypes,
  HealthCareProvider,
  Institution,
  InstitutionAddress,
  Physician
} from '../../../../types';
import ButtonText from '../../../shared/ButtonText';
import InstitutionAddressPicker from '../../../shared/InstitutionAddressPicker';
import Text from '../../../shared/Typography';
import Container from '../../../shared/Container';
import Skeleton from '../../../shared/Skeleton';
import PageContainer from '../../../shared/PageContainer';
import InstitutionCard from './InstitutionCard';
import Icon from '../../../shared/Icon';
import CreateInstitutionForm from './CreateInstitutionForm';
import Button from '../../../shared/Button';
import { useTranslate } from '../../../../hooks/useTranslate';
import { generateKey, isEmptyArray } from '../../../../utils';

interface InstitutionPhysicianPickerProps {
  t: any;
  handleNext: () => void;
  dispatch: React.Dispatch<CreateRequestReducerActions>;
  healthCareProviders: HealthCareProvider[];
  translationFileReady: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '3rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentSection: {
    width: '100%',
    maxWidth: '54.5rem',
    boxShadow: 'none',
    background: 'none',
    marginTop: '4.5rem',
    [theme.breakpoints.up('sm')]: {
      boxShadow: '0rem 0rem 1.6rem rgb(42 10 90 / 12%)',
      background: 'white',
      padding: '3rem'
    }
  },
  headingContainer: {
    alignSelf: 'flex-start'
  },
  pageContainer: {
    width: '100%',
    maxWidth: '54.5rem',
    marginTop: 0,
    marginBottom: '2rem',
    padding: '2rem',
    [theme.breakpoints.up('sm')]: {
      padding: '4rem'
    }
  },
  cardsSection: {
    width: '100%',
    maxWidth: '54.5rem'
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    margin: '0 1rem'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    minWidth: '1.6rem',
    minHeight: '5rem'
  },
  addAnotherInstitutionButton: {
    width: '100%',
    height: '3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  titleText: {
    marginBottom: '1rem'
  },
  descriptionText: {
    maxWidth: '65rem'
  }
}));

const LabelSkeleton = () => <Skeleton height='2.5rem' width='25rem' />;

const InstitutionPhysicianPicker = ({
  handleNext,
  healthCareProviders,
  dispatch,
  translationFileReady
}: InstitutionPhysicianPickerProps) => {
  const classes = useStyles();

  const [openInstitutionModal, setOpenInstitutionModal] = useState(false);
  const [showInstitutionAddressPicker, setShowInstitutionAddressPicker] = useState(!healthCareProviders?.length);

  const { t: requestFormTFunction } = useTranslate('requestForm');

  const showInstitutionModal = () => {
    setOpenInstitutionModal(true);
  };

  const selectHealthCareProvider = (institution: Institution, address: InstitutionAddress, physician?: Physician) => {
    dispatch({
      type: CreateRequestReducerActionTypes.ADD_HEALTH_CARE_PROVIDER,
      payload: {
        institution,
        address,
        physician
      }
    });
    setShowInstitutionAddressPicker(false);
  };

  const removeHealthCareProvider = (index: number) => {
    dispatch({
      type: CreateRequestReducerActionTypes.REMOVE_HEALTHCARE_PROVIDER,
      payload: { index }
    });
  };

  const selectPhysicianForInstitution = (physician: Physician, index: number) => {
    dispatch({
      type: CreateRequestReducerActionTypes.ADD_PHYSICIAN_TO_INSTITUTION,
      payload: { physician, index }
    });
  };

  const setNotesForRequest = (notes: string, index: number) => {
    dispatch({
      type: CreateRequestReducerActionTypes.ADD_NOTES_TO_REQUEST,
      payload: { notes, index }
    });
  };

  const removeNotesForRequest = (index: number) => {
    dispatch({
      type: CreateRequestReducerActionTypes.REMOVE_NOTES_FROM_REQUEST,
      payload: { index }
    });
  };

  const removePhysicianForInstitution = (index: number) => {
    dispatch({
      type: CreateRequestReducerActionTypes.REMOVE_PHYSICIAN_FROM_INSTITUTION,
      payload: { index }
    });
  };

  useEffect(() => {
    if (!(healthCareProviders.length > 0)) {
      setShowInstitutionAddressPicker(true);
    }
  }, [healthCareProviders]);

  return (
    <Container className={classes.root}>
      <div className={classes.headingContainer}>
        <Text h2 color='ship-cove' className={classes.titleText}>
          {translationFileReady ? (
            `${requestFormTFunction('physicianInstitution.title')} (${requestFormTFunction('optional')})`
          ) : (
            <Skeleton />
          )}
        </Text>
        <Text paragraph color='ship-cove' className={classes.descriptionText}>
          {translationFileReady ? requestFormTFunction('physicianInstitution.description') : <LabelSkeleton />}
        </Text>
      </div>
      <PageContainer className={classes.contentSection}>
        <div className={classes.cardsSection}>
          {healthCareProviders && healthCareProviders.length > 0 && (
            <>
              {healthCareProviders.map((provider, index) => {
                const institutionAddress = provider.address;
                return (
                  <PageContainer
                    key={generateKey(provider.address.id, provider.institution.id, index)}
                    className={classes.pageContainer}
                  >
                    <InstitutionCard
                      key={`hc-provider-${index}`}
                      institutionAddress={institutionAddress}
                      removeHealthCareProvider={() => removeHealthCareProvider(index)}
                      institution={provider.institution}
                      notes={provider.notes}
                      selectPhysicianForInstitution={(physician) => selectPhysicianForInstitution(physician, index)}
                      setNotesForRequest={(notes) => setNotesForRequest(notes, index)}
                      removeNotes={() => removeNotesForRequest(index)}
                      physician={provider.physician}
                      removePhysician={() => removePhysicianForInstitution(index)}
                    />
                  </PageContainer>
                );
              })}
            </>
          )}
        </div>
        {(showInstitutionAddressPicker || !(healthCareProviders.length > 0)) && (
          <div style={{ marginTop: healthCareProviders.length > 0 ? '4rem' : 0 }}>
            <Text paragraph color='ship-cove' style={{ marginBottom: '0.5rem' }}>
              {translationFileReady ? requestFormTFunction('physicianInstitution.institutionTitle') : <LabelSkeleton />}
            </Text>
            <InstitutionAddressPicker
              addNewProviderButtonText={requestFormTFunction(
                'physicianAndInstitutionPicker.healthcareProviderInput.addNewProviderButtonText'
              )}
              hidden={!showInstitutionAddressPicker}
              onSelect={selectHealthCareProvider}
              onShowModal={showInstitutionModal}
              // exclude selected addresses
              excludeAddressIds={healthCareProviders.map((provider) => provider.address.id)}
            />
            <Text paragraph color='ship-cove' style={{ marginTop: '3rem' }}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <Trans
                t={requestFormTFunction}
                i18nKey='physicianAndInstitutionPicker.fillInstitutionFormText'
                components={{
                  coloredText: <Text fontWeight={500} span color='purple' />
                }}
              />
            </Text>
            <CreateInstitutionForm
              open={openInstitutionModal}
              onClose={() => setOpenInstitutionModal(false)}
              setInstitutionAddress={selectHealthCareProvider}
            />
          </div>
        )}
        {!showInstitutionAddressPicker && healthCareProviders.length <= 2 && (
          <div className={classes.addAnotherInstitutionButton}>
            <Icon name='add-mint-circle-purple' style={{ height: '2rem' }} />
            <ButtonText
              isPrimary
              style={{ margin: '0 0 0 1rem', lineHeight: '2rem' }}
              label={requestFormTFunction('physicianAndInstitutionPicker.addAnotherProviderButtonText')}
              onClick={() => setShowInstitutionAddressPicker(true)}
            />
          </div>
        )}
        <div className={classes.buttonContainer}>
          <Button
            style={{ padding: '2rem 2rem' }}
            type='button'
            onClick={() => handleNext()}
            label={isEmptyArray(healthCareProviders) ? requestFormTFunction('skip') : requestFormTFunction('continue')}
          />
        </div>
      </PageContainer>
    </Container>
  );
};

export default InstitutionPhysicianPicker;
