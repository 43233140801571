import { makeStyles } from '@material-ui/core/styles';
import reactStringReplace from 'react-string-replace';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { ReactNode, useContext, useEffect, useState } from 'react';
import Text from '../../shared/Typography';
import PageContainer from '../../shared/PageContainer';
import Skeleton from '../../shared/Skeleton';
import SignaturePad from '../../shared/SignaturePad';
import Container from '../../shared/Container';
import { AuthContext } from '../../../contexts/auth-context';
import Checkbox from '../../shared/Checkbox';
import Button from '../../shared/Button';
import { useTranslate } from '../../../hooks/useTranslate';
import apiService from '../../../services/api-service';
import { VersionedText } from '../../../types/common/VersionedText';
import { getReplacementFromVersionedText, replaceVersionedText } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: '3rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headingContainer: {
    alignSelf: 'flex-start'
  },
  pageContainer: {
    padding: '3.6rem 4rem',
    maxWidth: '55rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '2.4rem 1.8rem'
    }
  },
  titleText: {
    marginBottom: '1rem'
  },
  descriptionText: {
    maxWidth: '65rem'
  },
  buttonContainer: {
    minWidth: '1.6rem',
    minHeight: '5rem'
  },
  button: {
    marginBottom: '1rem'
  },
  titleSkeleton: {
    width: '27.7rem',
    height: '2.5rem',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '39rem'
    }
  },
  descriptionSkeleton: {
    width: '26.1rem',
    height: '18.6rem',
    [theme.breakpoints.up('xs')]: {
      width: '38.6rem',
      height: '11.4rem'
    }
  },
  authorizedCheckboxLabel: {
    fontWeight: 500,
    color: theme.palette.grey[60]
  },
  autorizeCheckboxContainer: {
    alignItems: 'initial',
    marginBottom: '3rem'
  },
  autorizeCheckbox: {
    margin: '5px 23px 5px 5px'
  },
  signatureContainer: {
    width: '100%',
    marginBottom: '2.5rem'
  }
}));

interface AuthorizationProps {
  signatureUrl: string | undefined;
  saveSignatureUrl: (url: string | undefined) => void;
  handleNext: () => void;
  isConsentAuthorized: boolean;
  handleConsentAuthorized: (isAuthorized: boolean, setConfirmationTextId: number) => void;
}

interface ConfirmationTextResponse extends VersionedText {}

const LabelSkeleton = () => <Skeleton height='2.5rem' width='25rem' />;

const Authorization = ({
  signatureUrl,
  saveSignatureUrl,
  handleNext,
  isConsentAuthorized,
  handleConsentAuthorized
}: AuthorizationProps) => {
  const classes = useStyles();
  const { institution } = useContext(AuthContext);
  const [hasSignature, setHasSignature] = useState(false);
  const [loadingSignature, setLoadingSignature] = useState(false);
  const [showError, setShowError] = useState(false);
  const { t: requestFormTFunction, ready: isRequestFormTranslationReady } = useTranslate('requestForm');

  const { data: confirmationRequest, isLoading: isConfirmationRequestLoading } = useQuery<ConfirmationTextResponse>(
    'authorization-step-confirmation-text',
    () => {
      return apiService.get('/public/portal/information_requests/confirmation_text');
    },
    {
      // This data will be kept fresh for 30 minutes before becoming stale and forced to be refetched
      staleTime: 30 * 60 * 1000,
      // Time the Inactive Cache will live in memory for 35 mins before it is garbaged and have to be refetched
      cacheTime: 35 * 60 * 1000
    }
  );

  const handleContinue = () => {
    setLoadingSignature(true);
    if (isConsentAuthorized) {
      setShowError(false);
      handleNext();
    } else setShowError(true);
  };

  /**
   * Wraps the versioned text and replaces link text with proper element and styling
   */
  const formatLinkTextFromVersionedText = (
    textToFormat: string | ReactNode[] | undefined,
    versionedText: VersionedText,
    replacementKey: string
  ) => {
    return reactStringReplace(textToFormat, replacementKey, () => {
      // format the key without the curly brackets and use it to find the replacement object in the versioned text
      let linkName = replacementKey;
      linkName = linkName.replace('{{', '');
      linkName = linkName.replace('}}', '');

      const replacement = getReplacementFromVersionedText(versionedText, linkName);

      return (
        <Text a openInNewTab href={replacement?.value}>
          {linkName}
        </Text>
      );
    });
  };

  const getAuthorizationText = () => {
    if (confirmationRequest && institution) {
      // Replace parts of the versioned text with the proper institution
      let textToDisplay: ReactNode[] | string = replaceVersionedText(confirmationRequest, {
        institution_name: institution
      });

      // Formats any link replacements found in the versioned text and wrap it with the proper element and styling
      if (confirmationRequest?.replacements) {
        confirmationRequest?.replacements.forEach((replacement) => {
          if (replacement.type === 'link') {
            textToDisplay = formatLinkTextFromVersionedText(
              textToDisplay,
              confirmationRequest,
              `{{${replacement.key}}}`
            );
          }
        });
      }

      return textToDisplay;
    }
  };

  useEffect(() => {
    if (hasSignature) {
      setLoadingSignature(false);
    }
  }, [hasSignature, setLoadingSignature]);

  return (
    <Container className={classes.contentContainer}>
      <div className={classes.headingContainer}>
        <Text h2 color='ship-cove' className={classes.titleText}>
          {isRequestFormTranslationReady ? requestFormTFunction('authorization.title') : <Skeleton />}
        </Text>
        <Text paragraph color='ship-cove' className={classes.descriptionText}>
          {isRequestFormTranslationReady ? requestFormTFunction('authorization.description') : <LabelSkeleton />}
        </Text>
      </div>
      <PageContainer className={classes.pageContainer}>
        <div className={classes.signatureContainer}>
          <SignaturePad
            signatureUrl={signatureUrl}
            saveSignatureUrl={(url, isEmpty) => {
              saveSignatureUrl(url);
              if (isEmpty) {
                setHasSignature(false);
                saveSignatureUrl(undefined);
              } else {
                setHasSignature(true);
              }
            }}
          />
        </div>
        {!isConfirmationRequestLoading && confirmationRequest && institution ? (
          <Checkbox
            label={
              <Text paragraph color='grey-60'>
                <>{getAuthorizationText()}</>
              </Text>
            }
            checked={isConsentAuthorized}
            onCheckedChange={(checkedState) =>
              confirmationRequest && handleConsentAuthorized(!!checkedState, confirmationRequest.id)
            }
            labelClassname={classes.authorizedCheckboxLabel}
            containerClassName={classes.autorizeCheckboxContainer}
            className={classes.autorizeCheckbox}
          />
        ) : (
          <Skeleton
            width='470px'
            height='96px'
            style={{ margin: '0 0 3rem 0' }}
            className={clsx(classes.autorizeCheckbox)}
          />
        )}

        {showError && (
          <Text paragraph bold align='center' color='error' style={{ marginBottom: '1.6rem' }}>
            {requestFormTFunction('errors.checkboxRequired')}
          </Text>
        )}
        <Container className={classes.buttonContainer}>
          {isRequestFormTranslationReady ? (
            <Button
              style={{ minWidth: '24rem' }}
              isLoading={loadingSignature}
              isDisabled={signatureUrl ? !isConsentAuthorized || !signatureUrl : !isConsentAuthorized || !hasSignature}
              type='button'
              label={loadingSignature ? '' : requestFormTFunction('continue')}
              onClick={handleContinue}
            />
          ) : (
            <Skeleton width='10rem' height='4.6rem' />
          )}
        </Container>
      </PageContainer>
    </Container>
  );
};

export default Authorization;
