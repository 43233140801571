import React, { RefObject, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Icon from './Icon';
import { conditionalProps, hexToRGB, isNotUndefined } from '../../utils';
import Button from './Button';
import { IconNames } from '../../types/common/IconTypes';
import IconButton from './IconButton';
import { useTranslate } from '../../hooks/useTranslate';
import useId from '../../hooks/useId';

interface CommonProps {
  label?: string | any;
  hideLabelAbove?: boolean;
  children?: React.ReactNode;
  toggleVisibilityButton?: boolean;
  hideLabel?: boolean;
  error?: string;
  focusOnMount?: boolean;
  maxLength?: number;
  iconName?: IconNames;
  pattern?: string;
  maxWidth?: boolean;
  inputClassName?: string;
  buttonRight?: string;
  iconPosition?: 'start' | 'end';
  iconClassName?: string;
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  labelProps?: Omit<React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>, 'htmlFor'>;
  wrapperProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  errorClassName?: string;
  toggleInputError?: boolean;
  numeric?: boolean;
  noPlaceholders?: boolean;
}

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & CommonProps;
export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & CommonProps;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '1rem 0'
  },
  label: {
    fontSize: '1.5rem',
    color: theme.palette.grayscale['60'],
    fontWeight: 300
  },
  hidelabel: {
    opacity: 0
  },
  input: {
    outline: 'none',
    borderRadius: '3.6rem',
    backgroundColor: 'white',
    width: '100%',
    padding: '1rem 2rem',
    fontSize: '1.5rem',
    color: theme.palette.grayscale['30'],
    border: `0.2rem solid ${theme.palette.grey['80']}`,
    height: '100%',
    '&:focus': {
      color: theme.palette.text.main,
      border: `0.2rem solid ${theme.palette.primary.main}`
    },
    '&:disabled': {
      borderRadius: '1.6rem',
      fontWeight: 300,
      borderWidth: '0.1rem'
    },
    '&::placeholder': {
      color: hexToRGB(theme.palette.text.main, 0.3)
    }
  },
  inputIcon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    margin: '0.5rem 0rem'
  },
  withToggleButton: {
    paddingRight: '3.2rem'
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
  maxWidth: {
    width: '100%'
  },
  visibilityButton: {
    right: 10,
    position: 'absolute',
    background: 'none',
    padding: 0
  },
  clippedFieldWidth: {
    paddingRight: '11rem'
  },
  optionalLabel: {
    fontSize: '1.2rem',
    marginLeft: '0.75rem',
    color: theme.palette.text.main,
    fontStyle: 'italic'
  },
  withIconLeft: {
    paddingLeft: '3rem'
  },
  withIconRight: {
    paddingRight: '3rem'
  },
  iconStyles: {
    position: 'absolute',
    '&.start': {
      left: 10
    },
    '&.end': {
      right: 10
    }
  },
  textArea: {
    borderRadius: '1.6rem'
  }
}));

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const classes = useStyles();
  const { label, className, error, focusOnMount, maxWidth, placeholder, hideLabel, ...others } = props;

  const textAreaId = useId({ prefix: 'textarea', defaultValue: others.id });

  return (
    <div className={classes.root}>
      {!hideLabel && (
        <label htmlFor={textAreaId} className={clsx(classes.label, hideLabel && classes.hidelabel)}>
          {label}
        </label>
      )}
      <textarea
        {...others}
        id={textAreaId}
        ref={ref as RefObject<HTMLTextAreaElement>}
        className={clsx(
          classes.input,
          classes.textArea,
          error && classes.error,
          classes.inputIcon,
          maxWidth && classes.maxWidth,
          className
        )}
        autoFocus={focusOnMount}
        placeholder={placeholder || ''}
      />
    </div>
  );
});

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const containerRef = useRef<HTMLDivElement>();
  const {
    value,
    children,
    name,
    label,
    hideLabelAbove,
    className,
    toggleVisibilityButton,
    type,
    error,
    focusOnMount,
    maxLength,
    iconName,
    maxWidth,
    inputClassName,
    buttonRight,
    iconPosition,
    placeholder,
    buttonProps,
    labelProps,
    wrapperProps,
    errorClassName,
    toggleInputError,
    hideLabel,
    numeric,
    noPlaceholders,
    ...others
  } = props;

  const icon = showPassword ? 'close' : 'open';
  const { t } = useTranslate('passwordToggle');

  const inputId = useId({ defaultValue: others.id, prefix: `input-${name}` });
  const helperTextId = useId({ prefix: 'input-helper-text' });

  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };

  const InputIcon = () => {
    return <Icon name={iconName} className={clsx(classes.iconStyles, iconPosition)} />;
  };

  const [inputType, setInputType] = useState(type);

  useEffect(() => {
    if (!isNotUndefined(toggleVisibilityButton) || type) return;
    return setInputType(showPassword ? 'text' : 'password');
  }, [toggleVisibilityButton, showPassword, type]);

  return (
    <div className={clsx(classes.root, wrapperProps?.className)}>
      {!hideLabelAbove && (
        <label
          {...labelProps}
          className={clsx(classes.label, hideLabel && classes.hidelabel, labelProps && labelProps?.className)}
          htmlFor={inputId}
        >
          {label}
        </label>
      )}
      <div
        {...wrapperProps}
        className={clsx(classes.inputIcon, maxWidth && classes.maxWidth, inputClassName, wrapperProps?.className)}
        ref={containerRef as RefObject<HTMLDivElement>}
      >
        {iconName && iconPosition && <InputIcon />}
        <input
          title={label}
          name={name}
          id={inputId}
          max={maxLength}
          ref={ref}
          className={clsx(
            classes.input,
            className,
            (error || toggleInputError) && classes.error,
            toggleVisibilityButton && classes.withToggleButton,
            buttonRight && classes.clippedFieldWidth,
            iconName && iconPosition === 'start' && classes.withIconLeft,
            iconName && iconPosition === 'end' && classes.withIconRight,
            maxWidth && classes.maxWidth
          )}
          value={value}
          type={inputType}
          autoFocus={focusOnMount}
          placeholder={!noPlaceholders ? placeholder || label : ''}
          maxLength={maxLength}
          {...conditionalProps({ 'aria-describedby': helperTextId, 'aria-invalid': true }, !!error)}
          {...others}
          onChange={(e) => {
            const { value } = e.currentTarget;
            if (maxLength && value.length > maxLength) return;
            if (numeric && isNaN(Number(value))) return;
            others && others.onChange && others.onChange(e);
          }}
        />
        {toggleVisibilityButton && (
          <IconButton
            className={classes.visibilityButton}
            onClick={handleClick}
            label={showPassword ? t('hidePassword') : t('showPassword')}
          >
            <Icon size='medium' name={`eye-${icon}` as IconNames} />
          </IconButton>
        )}
        {buttonRight && (
          <Button
            onClick={handleClick}
            className={clsx(classes.visibilityButton, 'is-primary')}
            label={buttonRight}
            {...buttonProps}
          />
        )}
      </div>
      {!!children && children}
      {error && (
        <p
          className={clsx('error-text', errorClassName)}
          id={helperTextId}
          style={{ maxWidth: containerRef.current?.offsetWidth }}
        >
          {error}
        </p>
      )}
    </div>
  );
});

export default Input;
