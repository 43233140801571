import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import Container from '../../shared/Container';
import Text from '../../shared/Typography';
import { useTranslate } from '../../../hooks/useTranslate';
import Skeleton from '../../shared/Skeleton';
import PageContainer from '../../shared/PageContainer';
import { SubmitFunction, useForm, ValidationErrors } from '../../../hooks/useForm';
import Input from '../../shared/Input';
import { hexToRGB } from '../../../utils';
import Button from '../../shared/Button';
import { AuthContext } from '../../../contexts/auth-context';
import AlertContainer from '../../shared/AlertContainer';
import { usePasswordStrengthCheck } from '../../../hooks/usePasswordStrengthCheck';
import { MINIMUM_ACCEPTABLE_PASSWORD_STRENGTH_SCORE } from '../../../utils/constants';
import MailToLink from '../../shared/MailToLink';
import { useRoutes } from '../../../hooks/useRoutes';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: '3rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',
    [theme.breakpoints.up('sm')]: {
      padding: '21px 60px'
    }
  },
  headingContainer: {
    alignSelf: 'flex-start',
    marginTop: '4rem'
  },
  titleText: {
    marginBottom: '1rem'
  },
  descriptionText: {
    maxWidth: '65rem'
  },
  titleSkeleton: {
    height: '3.2rem',
    width: '30rem'
  },
  descriptionSkeleton: {
    width: '27rem',
    height: '4.8rem',
    [theme.breakpoints.up('xs')]: {
      width: '46rem',
      height: '2.4rem'
    }
  },
  pageContainer: {
    marginTop: '7rem'
  },
  tabsContainer: {
    [theme.breakpoints.up('sm')]: {
      width: 'fit-content'
    }
  },
  formContainer: {
    width: '100%',
    padding: '4rem 0',
    maxWidth: '40rem',
    minWidth: '30rem',
    [theme.breakpoints.up('sm')]: {
      minWidth: '40rem'
    }
  },
  sectionContainer: {
    minHeight: '4.6rem',
    width: '100%',
    padding: '0 2.7rem',
    marginBottom: '3rem'
  },
  inputRow: {
    position: 'relative'
  },
  inputRoot: {
    marginTop: 0,
    marginLeft: 0
  },
  input: {
    fontWeight: 500,
    width: '100%',
    padding: '1rem 2rem',
    borderRadius: '3.6rem',
    margin: 0,
    border: `0.2rem solid ${theme.palette.grey['80']}`,
    '&:focus': {
      color: theme.palette.text.main,
      border: `0.2rem  solid ${theme.palette.primary.main}`
    },
    '&:disabled': {
      fontWeight: 500,
      border: 'none',
      textAlign: 'left'
    },
    '&::placeholder': {
      color: hexToRGB(theme.palette.text.main, 0.3)
    },
    '&[aria-invalid=true]': {
      color: theme.palette.error.main,
      border: `0.2rem solid ${theme.palette.error.main}`
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem'
    }
  },
  buttonContainer: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '16rem'
  }
}));

export const updatePasswordInitialValues = {
  password: '',
  confirmPassword: ''
};

interface Params {
  token: string;
}
type UpdatePasswordInitialValues = typeof updatePasswordInitialValues;
type UpdatePasswordFormValues = typeof updatePasswordInitialValues;

const UpdatePassword = () => {
  const history = useHistory();
  const params = useParams<Params>();
  const classes = useStyles();
  const { t, ready } = useTranslate('login');
  const { t: tFormErrors } = useTranslate('formErrors');
  const { updatePassword, validatePasswordReset } = useContext(AuthContext);
  const location = useLocation();
  const { routesTFunction } = useRoutes();
  const { checkPasswordStrength } = usePasswordStrengthCheck();

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState<React.ReactNode>('');

  const defaultInputProps = {
    wrapperProps: { className: classes.inputRoot },
    className: clsx(classes.input),
    maxWidth: true
  };

  const { getFieldProps, handleSubmit } = useForm(updatePasswordInitialValues, {
    validate: (values: any) => {
      const errors: ValidationErrors<UpdatePasswordInitialValues> = {};

      const { score: passwordScore, warning, suggestions } = checkPasswordStrength(values.password);
      if (!values.password || passwordScore < MINIMUM_ACCEPTABLE_PASSWORD_STRENGTH_SCORE) {
        errors.password = warning || suggestions[0] || tFormErrors('invalidPassword');
      }

      if (!values.confirmPassword) {
        errors.confirmPassword = tFormErrors('invalidPassword');
      }

      if (values.confirmPassword !== values.password) {
        errors.confirmPassword = tFormErrors('confirmPasswordNotMatch');
      }

      return errors;
    }
  });

  const onSubmit: SubmitFunction<UpdatePasswordFormValues> = ({ password }, { setIsFormSubmitting }) => {
    const { token } = params;

    updatePassword(password, token, location.search)
      .then(() => {
        setLoading(false);
        history.push({
          pathname: routesTFunction('redirectPaths./login'),
          state: { from: 'update-password-successful' }
        });
      })
      .catch((err) => {
        console.error(err);
        setFormError(err.translatedMessage);
        setLoading(false);
      })
      .finally(() => {
        setIsFormSubmitting(false);
      });
  };

  useEffect(() => {
    const { token } = params;
    validatePasswordReset(token, location.search).catch((errArray) => {
      const err = errArray[0];
      if (err.translatedMessage) {
        setFormError(err.translatedMessage);
      } else {
        setFormError(
          <>
            {t('updateError')} <MailToLink />
          </>
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, params, validatePasswordReset]);

  return (
    <Container className={classes.contentContainer}>
      <div className={classes.headingContainer}>
        <Text h2 color='ship-cove' className={classes.titleText}>
          {ready ? t('updateHeading') : <Skeleton className={classes.titleSkeleton} />}
        </Text>
        <Text paragraph color='ship-cove' className={classes.descriptionText}>
          {ready ? t('updateHeadingDescription') : <Skeleton className={classes.descriptionSkeleton} />}
        </Text>
      </div>
      <PageContainer className={classes.pageContainer}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.formContainer} noValidate>
          <Container className={classes.sectionContainer}>
            <div className={classes.inputRow}>
              <Input
                {...defaultInputProps}
                {...getFieldProps('password')}
                label={t('password')}
                placeholder=''
                type='password'
              />
            </div>
            <div className={classes.inputRow}>
              <Input
                {...defaultInputProps}
                {...getFieldProps('confirmPassword')}
                label={t('confirmPassword')}
                placeholder=''
                type='password'
              />
            </div>
            {formError && (
              <AlertContainer>
                <div className='auth-form__errors'>
                  <Text paragraph className='is-error auth-input__error'>
                    {formError}
                  </Text>
                </div>
              </AlertContainer>
            )}
          </Container>

          <Container className={classes.buttonContainer}>
            {ready ? (
              <Button
                style={{ width: '100%' }}
                type='submit'
                isLoading={loading}
                label={loading ? '' : t('updateReset')}
              />
            ) : (
              <Skeleton width='10rem' height='4.6rem' />
            )}
          </Container>
        </form>
      </PageContainer>
    </Container>
  );
};

export default UpdatePassword;
