import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useMemo, useContext } from 'react';
import { GetFieldPropsFunction, SetValueFunction, ValidateFieldFunction } from '../../../../hooks/useForm';
import Container from '../../../shared/Container';
import Input from '../../../shared/Input';
import { hexToRGB, provinces } from '../../../../utils';
import Text from '../../../shared/Typography';
import Skeleton from '../../../shared/Skeleton';
import DropdownInput from '../../../shared/DropdownInput';
import { useTranslate } from '../../../../hooks/useTranslate';
import { PersonalInformationType } from '../../../../types';
import { AuthContext } from '../../../../contexts/auth-context';
import { UserDataRequest } from '..';
import Button from '../../../shared/Button';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '100%',
    padding: '4rem 0',
    maxWidth: '54rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  sectionContainer: {
    minHeight: '4.6rem',
    width: '100%',
    padding: '0 2.7rem',
    marginBottom: '3rem'
  },
  inputRoot: {
    marginTop: 0,
    marginLeft: 0
  },
  inputLabel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '1.5rem',
    color: theme.palette.grayscale['60'],
    fontWeight: 300
  },
  inputRow: {
    position: 'relative'
  },
  input: {
    fontWeight: 500,
    width: '100%',
    padding: '1rem 2rem',
    borderRadius: '3.6rem',
    margin: 0,
    border: `0.2rem solid ${theme.palette.grey['80']}`,
    '&:focus': {
      color: theme.palette.text.main,
      border: `0.2rem  solid ${theme.palette.primary.main}`
    },
    '&:disabled': {
      fontWeight: 500,
      border: 'none',
      textAlign: 'left'
    },
    '&::placeholder': {
      color: hexToRGB(theme.palette.text.main, 0.3)
    },
    '&[aria-invalid=true]': {
      color: theme.palette.error.main,
      border: `0.2rem solid ${theme.palette.error.main}`
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem'
    }
  },
  customInputError: {
    fontWeight: 300,
    fontSize: '1.5rem',
    marginBottom: '1.2rem',
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
  inputNoErrorModifier: {
    display: 'none'
  },
  addressInputGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonContainer: {
    minWidth: '1.6rem',
    minHeight: '5rem'
  },
  dateGuiderText: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    bottom: '-1.5rem'
  },
  // Date styles
  dateInput: {
    // padding: '13px 24px',
    // minWidth: '106px',
    textAlign: 'center',
    MozAppearance: 'textfield',
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none'
    }
  },
  dateInputGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2rem'
  },
  dateInputWrapper: {
    position: 'relative'
  },
  dateInputLabel: {
    position: 'absolute',
    bottom: '-14.5px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '15px',
    fontWeight: 400,
    color: theme.palette.grayscale[60]
  }
}));

const provincesForDropDown = ['', ...provinces];

const LabelSkeleton = () => <Skeleton height='2.5rem' width='25rem' />;

interface PersonalInformationFormUIProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  getFieldProps: GetFieldPropsFunction<PersonalInformationType>;
  submitError?: string;
  submitLoading?: boolean;
  validateField: ValidateFieldFunction<any>;
  values: PersonalInformationType;
  loggedInUserData?: UserDataRequest;
  setValue: SetValueFunction<any>;
  handleClickOutside: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: Partial<Record<keyof PersonalInformationType, string>>;
}

const PersonalInformationFormUI = ({
  onSubmit,
  getFieldProps,
  submitError,
  submitLoading,
  validateField,
  values,
  loggedInUserData,
  handleChange,
  handleClickOutside,
  errors
}: PersonalInformationFormUIProps) => {
  const classes = useStyles();
  const isMobilePlus = useMediaQuery('(min-width:425px)');
  const { isLoggedIn } = useContext(AuthContext);

  const { t: provinceTFunction, ready: isProvinceTranslationReady } = useTranslate('provinces');
  const { t: requestFormTFunction, ready: isRequestFormTranslationReady } = useTranslate('requestForm');

  const mappedProvinces = useMemo(() => {
    if (isProvinceTranslationReady) {
      return provincesForDropDown.map((province) => {
        if (!province) return { label: '', value: '' };
        return {
          value: province,
          label: provinceTFunction(`${province}` as any)
        };
      });
    }
    return [{ label: '', value: '' }];
  }, [provinceTFunction, isProvinceTranslationReady]);

  const defaultInputProps = {
    wrapperProps: { className: classes.inputRoot },
    className: clsx(classes.input),
    maxWidth: true
  };

  return (
    <form onSubmit={onSubmit} className={classes.formContainer} noValidate>
      <Container className={classes.sectionContainer}>
        <div className={classes.inputRow}>
          <label htmlFor={getFieldProps('first_name').name} className={clsx(classes.inputLabel)}>
            {isRequestFormTranslationReady ? requestFormTFunction('personalInformation.firstName') : <LabelSkeleton />}
          </label>
          <Input disabled={loggedInUserData?.isVerified} {...defaultInputProps} {...getFieldProps('first_name')} />
        </div>
        <div className={classes.inputRow}>
          <label htmlFor={getFieldProps('last_name').name} className={clsx(classes.inputLabel)}>
            {isRequestFormTranslationReady ? requestFormTFunction('personalInformation.lastName') : <LabelSkeleton />}
          </label>
          <Input disabled={loggedInUserData?.isVerified} {...defaultInputProps} {...getFieldProps('last_name')} />
        </div>
        <div className={classes.inputRow}>
          <div className={clsx(classes.inputLabel)}>
            <label htmlFor={getFieldProps('dob').name}>
              {isRequestFormTranslationReady ? requestFormTFunction('personalInformation.dob') : <LabelSkeleton />}
            </label>
          </div>
          <div className={classes.dateInputGroup}>
            <div className={classes.dateInputWrapper} style={{ width: '32%' }}>
              <Input
                className={clsx(classes.dateInput)}
                wrapperProps={{ className: classes.inputRoot }}
                errorClassName={classes.inputNoErrorModifier}
                name='month'
                value={values.month}
                error={getFieldProps('month').error}
                onChange={(e) => handleChange(e)}
                onBlur={handleClickOutside}
                numeric
                maxLength={2}
                maxWidth
              />
            </div>
            <div className={classes.dateInputWrapper} style={{ width: '32%' }}>
              <Input
                className={clsx(classes.dateInput, classes.input)}
                errorClassName={classes.inputNoErrorModifier}
                wrapperProps={{ className: classes.inputRoot }}
                name='day'
                value={values.day}
                error={getFieldProps('day').error}
                onChange={(e) => handleChange(e)}
                onBlur={handleClickOutside}
                numeric
                maxLength={2}
                maxWidth
              />
            </div>
            <div className={classes.dateInputWrapper} style={{ width: '32%' }}>
              <Input
                className={clsx(classes.dateInput)}
                errorClassName={classes.inputNoErrorModifier}
                wrapperProps={{ className: classes.inputRoot }}
                name='year'
                value={values.year}
                error={getFieldProps('year').error}
                onChange={(e) => handleChange(e)}
                onBlur={handleClickOutside}
                numeric
                maxLength={4}
                maxWidth
              />
            </div>
          </div>
          {errors.dob && (
            <Text paragraph className='error-text' style={{ margin: '-2rem 0 1rem 0' }}>
              {errors.dob}
            </Text>
          )}
        </div>
        <div className={classes.inputRow}>
          <label htmlFor={getFieldProps('dob').name} className={clsx(classes.inputLabel)}>
            {isRequestFormTranslationReady ? requestFormTFunction('personalInformation.hcn') : <LabelSkeleton />}
          </label>
          <Input
            {...defaultInputProps}
            {...getFieldProps<HTMLInputElement>('insurance_number', {
              onChange: (e) => {
                e.persist();
                const { value } = e.target;
                validateField('insurance_number', { ...values, insurance_number: value });
              }
            })}
          />
        </div>
        <div className={classes.inputRow}>
          <label htmlFor={getFieldProps('dob').name} className={clsx(classes.inputLabel)}>
            {isRequestFormTranslationReady ? requestFormTFunction('personalInformation.email') : <LabelSkeleton />}
          </label>
          <Input
            disabled={loggedInUserData?.user?.email_verified || loggedInUserData?.isVerified}
            autoComplete={isLoggedIn ? 'no-autocomplete' : 'email'}
            {...defaultInputProps}
            {...getFieldProps('email')}
          />
        </div>
        <div className={classes.inputRow}>
          <label htmlFor={getFieldProps('dob').name} className={clsx(classes.inputLabel)}>
            {isRequestFormTranslationReady ? (
              requestFormTFunction('personalInformation.phoneNumber')
            ) : (
              <LabelSkeleton />
            )}
          </label>
          <Input {...defaultInputProps} {...getFieldProps('phone_number')} />
        </div>
        <div className={classes.inputRow}>
          <label htmlFor={getFieldProps('street_address').name} className={clsx(classes.inputLabel)}>
            {isRequestFormTranslationReady ? requestFormTFunction('personalInformation.address') : <LabelSkeleton />}
          </label>
          <Input
            {...defaultInputProps}
            {...getFieldProps('street_address')}
            placeholder={isRequestFormTranslationReady ? requestFormTFunction('personalInformation.address') : ''}
          />
          <Input
            {...defaultInputProps}
            {...getFieldProps('extra_line_1')}
            placeholder={isRequestFormTranslationReady ? requestFormTFunction('personalInformation.addressLine2') : ''}
          />
          {isMobilePlus ? (
            <>
              <div className={`${classes.addressInputGroup}`}>
                <div style={{ width: '49%', position: 'relative' }}>
                  <Input
                    {...defaultInputProps}
                    {...getFieldProps('city')}
                    placeholder={isRequestFormTranslationReady ? requestFormTFunction('personalInformation.city') : ''}
                  />
                </div>
                <div style={{ width: '49%', position: 'relative' }}>
                  <DropdownInput
                    {...defaultInputProps}
                    {...getFieldProps<HTMLSelectElement>('province', {
                      onChange: (e) => {
                        e.persist();
                        const { value } = e.target;
                        validateField('insurance_number', { ...values, province: value });
                      }
                    })}
                    options={mappedProvinces}
                    placeholderText={requestFormTFunction('personalInformation.province')}
                  />
                </div>
              </div>
              <div style={{ width: '49%', position: 'relative' }}>
                <Input
                  {...defaultInputProps}
                  {...getFieldProps('postal_code')}
                  placeholder={
                    isRequestFormTranslationReady ? requestFormTFunction('personalInformation.postalCode') : ''
                  }
                />
              </div>
            </>
          ) : (
            <>
              <Input
                {...defaultInputProps}
                {...getFieldProps('city')}
                placeholder={isRequestFormTranslationReady ? requestFormTFunction('personalInformation.city') : ''}
              />
              <Input
                {...defaultInputProps}
                {...getFieldProps('postal_code')}
                placeholder={
                  isRequestFormTranslationReady ? requestFormTFunction('personalInformation.postalCode') : ''
                }
              />
              <DropdownInput
                {...defaultInputProps}
                {...getFieldProps<HTMLSelectElement>('province', {
                  onChange: (e) => {
                    e.persist();
                    const { value } = e.target;
                    validateField('insurance_number', { ...values, province: value });
                  }
                })}
                options={mappedProvinces}
                noLabel
                placeholderText={requestFormTFunction('personalInformation.province')}
              />
            </>
          )}
        </div>
      </Container>
      {submitError && (
        <Text paragraph bold align='center' color='error' style={{ marginBottom: '1.6rem' }}>
          {requestFormTFunction('errors.generic')}
        </Text>
      )}
      <Container className={classes.buttonContainer}>
        {isRequestFormTranslationReady ? (
          <Button
            style={{ minWidth: '24rem' }}
            isLoading={submitLoading}
            type='submit'
            label={submitLoading ? '' : requestFormTFunction('continue')}
          />
        ) : (
          <Skeleton width='10rem' height='4.6rem' />
        )}
      </Container>
    </form>
  );
};

export default PersonalInformationFormUI;
