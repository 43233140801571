import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useContext, useState } from 'react';
import PartnerLogo from './shared/PartnerLogo';
import { AuthContext } from '../contexts/auth-context';
import { useTranslate } from '../hooks/useTranslate';
import Modal from './shared/Modal';
import Container from './shared/Container';
import Text from './shared/Typography';
import Button from './shared/Button';
import { useRoutes } from '../hooks/useRoutes';

export interface AppNavProps {
  logout?: () => void;
  logoutUser?: any;
  className?: string;
  public?: boolean;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    maxWidth: '46rem'
  },
  descriptionContainer: {
    marginBottom: '6rem'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    width: '48%'
  },
  cancelButton: {
    background: 'transparent'
  },
  confirmButton: {
    color: theme.palette.catSkillWhite.main,
    backgroundColor: theme.palette.mandy.main
  }
}));

const AppNav = (props: AppNavProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslate('login');
  const { institution, isLoggedIn } = useContext(AuthContext);
  const [open, setIsOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { routesTFunction } = useRoutes();

  const handleOpenLogoutModal = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    setIsOpen(false);
    history.push(`${routesTFunction('redirectPaths./logout')}${location.search}`);
  };

  return (
    <>
      <nav className={clsx('navbar', props.className)}>
        <div className={clsx('navbar-menu')}>
          <div className='navbar-left'>
            {institution && (
              <>
                <PartnerLogo partnerName={institution} />
                <Link className='navbar-institution' to='/'>
                  {institution}
                </Link>
              </>
            )}
          </div>
          <div className='navbar-right'>
            {ready && (
              <>
                {isLoggedIn ? (
                  <Button className='navbar-logout' onClick={handleOpenLogoutModal}>
                    {t('logout')}
                  </Button>
                ) : (
                  <>
                    <Link className='navbar-login' to='/login'>
                      {t('login')}
                    </Link>
                    <Link className='navbar-login' to='/signup'>
                      {t('signup')}
                    </Link>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </nav>
      <Modal
        open={open}
        onClose={handleClose}
        header={ready && t('logout')}
        showHeaderDivider={ready}
        className={classes.modal}
      >
        <Container>
          <div className={classes.descriptionContainer}>
            <Text paragraph>{t('logoutDescription')}</Text>
          </div>

          <div className={classes.buttonContainer}>
            <Button className={clsx(classes.button, classes.cancelButton)} onClick={handleClose}>
              {t('cancel')}
            </Button>
            <Button className={clsx(classes.button, classes.confirmButton)} onClick={handleConfirm}>
              {t('logoutConfirm')}
            </Button>
          </div>
        </Container>
      </Modal>
    </>
  );
};

export default AppNav;
