import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import Container from '../../shared/Container';
import Text from '../../shared/Typography';
import { useTranslate } from '../../../hooks/useTranslate';
import Skeleton from '../../shared/Skeleton';
import PageContainer from '../../shared/PageContainer';
import { SubmitFunction, useForm, ValidationErrors } from '../../../hooks/useForm';
import Input from '../../shared/Input';
import { hexToRGB, isValidEmail } from '../../../utils';
import Button from '../../shared/Button';
import { AuthContext } from '../../../contexts/auth-context';
import AlertContainer from '../../shared/AlertContainer';
import SuccessForgot from './SuccessForgot';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: '3rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',
    [theme.breakpoints.up('sm')]: {
      padding: '21px 60px'
    }
  },
  headingContainer: {
    alignSelf: 'flex-start',
    marginTop: '4rem'
  },
  titleText: {
    marginBottom: '1rem'
  },
  descriptionText: {
    maxWidth: '65rem'
  },
  titleSkeleton: {
    height: '3.2rem',
    width: '30rem'
  },
  descriptionSkeleton: {
    width: '27rem',
    height: '4.8rem',
    [theme.breakpoints.up('xs')]: {
      width: '46rem',
      height: '2.4rem'
    }
  },
  pageContainer: {
    marginTop: '7rem'
  },
  tabsContainer: {
    [theme.breakpoints.up('sm')]: {
      width: 'fit-content'
    }
  },
  formContainer: {
    width: '100%',
    padding: '4rem 0',
    maxWidth: '40rem',
    minWidth: '30rem',
    [theme.breakpoints.up('sm')]: {
      minWidth: '40rem'
    }
  },
  sectionContainer: {
    minHeight: '4.6rem',
    width: '100%',
    padding: '0 2.7rem',
    marginBottom: '3rem'
  },
  inputRow: {
    position: 'relative'
  },
  inputRoot: {
    marginTop: 0,
    marginLeft: 0
  },
  input: {
    fontWeight: 500,
    width: '100%',
    padding: '1rem 2rem',
    borderRadius: '3.6rem',
    margin: 0,
    border: `0.2rem solid ${theme.palette.grey['80']}`,
    '&:focus': {
      color: theme.palette.text.main,
      border: `0.2rem  solid ${theme.palette.primary.main}`
    },
    '&:disabled': {
      fontWeight: 500,
      border: 'none',
      textAlign: 'left'
    },
    '&::placeholder': {
      color: hexToRGB(theme.palette.text.main, 0.3)
    },
    '&[aria-invalid=true]': {
      color: theme.palette.error.main,
      border: `0.2rem solid ${theme.palette.error.main}`
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem'
    }
  },
  buttonContainer: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '16rem'
  }
}));

export const forgotPasswordInitialValues = {
  email: ''
};

type ForgotPasswordInitialValues = typeof forgotPasswordInitialValues;
type ForgotPasswordFormValues = typeof forgotPasswordInitialValues;
type VIEWS = 'FORM' | 'SUCCESS';

const ForgotPassword = () => {
  const classes = useStyles();
  const { t, ready } = useTranslate('login');
  const { t: tFormErrors } = useTranslate('formErrors');
  const { resetPassword, isLoggedIn } = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();

  const [view, setView] = useState<VIEWS>('FORM');
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState<React.ReactNode>('');

  const defaultInputProps = {
    wrapperProps: { className: classes.inputRoot },
    className: clsx(classes.input),
    maxWidth: true
  };

  const { getFieldProps, handleSubmit, values } = useForm(forgotPasswordInitialValues, {
    validate: (values: any) => {
      const errors: ValidationErrors<ForgotPasswordInitialValues> = {};

      if (!values.email || !isValidEmail(values.email)) {
        errors.email = tFormErrors('invalidEmail');
      }

      return errors;
    }
  });

  const onSubmit: SubmitFunction<ForgotPasswordFormValues> = ({ email }, { setIsFormSubmitting }) => {
    setLoading(true);

    resetPassword(email)
      .then(() => {
        setLoading(false);
        setView('SUCCESS');
      })
      .catch((err) => {
        console.error(err);
        setFormError(err);
        setLoading(false);
      })
      .finally(() => {
        setIsFormSubmitting(false);
      });
  };

  useEffect(() => {
    const redirectIfLoggedIn = () => {
      const params = new URLSearchParams(location.search);
      const prev = params.get('prev');
      if (prev) {
        history.push(prev);
      } else {
        history.push('/');
      }
    };

    if (isLoggedIn) {
      redirectIfLoggedIn();
    }
  }, [isLoggedIn, history, location]);

  return (
    <Container className={classes.contentContainer}>
      <div className={classes.headingContainer}>
        <Text h2 color='ship-cove' className={classes.titleText}>
          {ready ? t('forgotHeading') : <Skeleton className={classes.titleSkeleton} />}
        </Text>
        <Text paragraph color='ship-cove' className={classes.descriptionText}>
          {ready ? t('forgotHeadingDescription') : <Skeleton className={classes.descriptionSkeleton} />}
        </Text>
      </div>
      {view === 'FORM' && (
        <PageContainer className={classes.pageContainer}>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.formContainer} noValidate>
            <Container className={classes.sectionContainer}>
              <div className={classes.inputRow}>
                <Input {...defaultInputProps} {...getFieldProps('email')} label={t('email')} placeholder='' />
              </div>
              {formError && (
                <AlertContainer>
                  <div className='auth-form__errors'>
                    <Text paragraph className='is-error auth-input__error'>
                      {formError}
                    </Text>
                  </div>
                </AlertContainer>
              )}
            </Container>

            <Container className={classes.buttonContainer}>
              {ready ? (
                <Button
                  style={{ width: '100%' }}
                  type='submit'
                  isLoading={loading}
                  label={loading ? '' : t('forgotSendReset')}
                />
              ) : (
                <Skeleton width='10rem' height='4.6rem' />
              )}
            </Container>
          </form>
        </PageContainer>
      )}
      {view === 'SUCCESS' && <SuccessForgot email={values.email} />}
    </Container>
  );
};

export default ForgotPassword;
