import { makeStyles } from '@material-ui/core/styles';
import Text from '../../shared/Typography';
import PageContainer from '../../shared/PageContainer';
import Icon from '../../shared/Icon';
import Skeleton from '../../shared/Skeleton';
import { useTranslate } from '../../../hooks/useTranslate';

const useStyles = makeStyles((theme) => ({
  messageContainer: {
    width: '100%',
    marginTop: '6rem',
    maxWidth: '40rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none !important',
      backgroundColor: 'transparent'
    },
    padding: '2rem',
    [theme.breakpoints.up('sm')]: {
      padding: '4rem'
    }
  },
  messageDescriptionText: {
    maxWidth: '27rem',
    marginTop: '2.5rem',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '39rem'
    }
  },
  messageDescriptionSkeleton: {
    width: '26.1rem',
    height: '18.6rem',
    [theme.breakpoints.up('xs')]: {
      width: '38.6rem',
      height: '11.4rem'
    }
  }
}));

const SuccessForgot = ({ email }: { email: string }) => {
  const classes = useStyles();
  const { t, ready } = useTranslate('login');

  return (
    <PageContainer className={classes.messageContainer}>
      <Icon size='x-large' name='green-check' />
      <Text align='center' className={classes.messageDescriptionText} paragraph color='ship-cove'>
        {ready ? t('forgotSuccess', { email: email }) : <Skeleton className={classes.messageDescriptionSkeleton} />}
      </Text>
    </PageContainer>
  );
};

export default SuccessForgot;
