import { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import Input from './Input';
import Text from './Typography';
import Divider from './Divider';
import useOutsideClick from '../../hooks/useOutsideClick';
import { Institution, InstitutionAddress, Physician, Provider, PROVIDER_TYPE } from '../../types';
import { useDebounce } from '../../hooks/useDebounce';
import { isBlankString } from '../../utils';
import useQuery from '../../hooks/useQuery';
import Spinner from './Spinner';
import { useTranslate } from '../../hooks/useTranslate';

type HealthCareProviderDropDownItemProps = {
  provider: Provider;
  providerAddress: InstitutionAddress;
  isActive: (institutionId: number, institutionAddressId: number, physicianId?: number) => boolean;
  handleClick: (institution: Institution, address: InstitutionAddress, physician?: any) => any;
  providerIndex: number;
  scrollIntoView: boolean;
  block: ScrollLogicalPosition;
  query: string;
};

const HealthCareProviderDropDownItem = ({
  provider,
  providerAddress,
  isActive,
  handleClick,
  scrollIntoView,
  block,
  query
}: HealthCareProviderDropDownItemProps) => {
  const getProviderTitle = (provider: Provider, providerAddress?: InstitutionAddress) => {
    if (provider.providerType === PROVIDER_TYPE.INSTITUTION) {
      if (providerAddress?.name) {
        return `${provider.institution_name} – ${providerAddress.name}`;
      }
      return provider.institution_name;
    }
    if (provider.providerType === PROVIDER_TYPE.PHYSICIAN) {
      return `${provider.first_name} ${provider.last_name}`;
    }
  };

  const getProviderAddress = (address: InstitutionAddress) => {
    if (provider.providerType === PROVIDER_TYPE.INSTITUTION) {
      const providerAddress = [address.address, address.city, address.province].join(', ');
      return <div className='dropdown-subitem'>{handleHighlightText(providerAddress, query)}</div>;
    }
    if (provider.providerType === PROVIDER_TYPE.PHYSICIAN) {
      return (
        <div>
          <Text paragraph>{address.institution?.institution_name}</Text>
          <Text paragraph color='grey-60'>
            {[address.address, address.city, address.province].join(', ')}
          </Text>
        </div>
      );
    }
  };

  const isAddressActive = (address: InstitutionAddress) => {
    if (provider.providerType === PROVIDER_TYPE.INSTITUTION) {
      return isActive(provider.id, address.id);
    }
    if (provider.providerType === PROVIDER_TYPE.PHYSICIAN) {
      return isActive(address.institution!.id, address.id, provider.id);
    }
  };

  const handleSelection = (address: InstitutionAddress) => {
    if (provider.providerType === PROVIDER_TYPE.INSTITUTION) {
      handleClick(provider, address, null);
    } else if (provider.providerType === PROVIDER_TYPE.PHYSICIAN) {
      handleClick(address.institution!, address, provider);
    }
  };

  const handleHighlightText = (name: string, highlight: string) => {
    const formattedHightlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const parts = name.split(new RegExp(`(${formattedHightlight})`, 'gi'));

    return (
      <span>
        {parts.map((part: string, i: number) => (
          <span key={`${part}-${i}`} style={part.toLowerCase() === highlight.toLowerCase() ? { color: '#5B53FF' } : {}}>
            {part}
          </span>
        ))}
      </span>
    );
  };

  return (
    <div
      className={clsx('dropdown-item selectable', isAddressActive(providerAddress) && 'selected')}
      onClick={() => handleSelection(providerAddress)}
      ref={(ref) => {
        if (scrollIntoView && ref) ref.scrollIntoView({ block });
      }}
    >
      <div className={clsx('dropdown-item')}>
        {handleHighlightText(getProviderTitle(provider, providerAddress) || '', query)}
        <div className={clsx('dropdown-subitem')}>{getProviderAddress(providerAddress)}</div>
      </div>
    </div>
  );
};

interface ActiveAddress extends InstitutionAddress {
  index?: number;
}

interface Active {
  institution: Institution;
  address: ActiveAddress;
  physician?: Physician;
  providerIndex?: number;
}

export interface InstitutionAddressPickerProps {
  onSelect: (institution: Institution, address: InstitutionAddress, physician?: Physician) => void;
  addNewProviderButtonText?: string;
  hidden?: boolean;
  label?: string;
  onShowModal: () => void;
  includePhysicians?: boolean;
  excludeAddressIds?: number[];
}

const InstitutionAddressPicker = (props: InstitutionAddressPickerProps) => {
  const [query, setQuery] = useState('');
  const [active, setActive] = useState<Active | undefined>();
  const [healthCareProviders, setHealthCareProviders] = useState<{ providers: Provider[] } | undefined>();

  const { t: requestFormTFunction } = useTranslate('requestForm');

  const debounceQuery = useDebounce(
    useMemo(() => (isBlankString(query) ? '' : query), [query]),
    200
  );

  const { ref } = useOutsideClick<HTMLDivElement>(() => {
    setQuery('');
    setActive(undefined);
  });

  const { loading, makeRequest } = useQuery(`/public/portal/institutions?search=${debounceQuery}`, 'GET');

  useEffect(() => {
    const getNewAddresses = async () => {
      const response = await makeRequest();
      const { institutions } = response;

      if (institutions) {
        setHealthCareProviders({ providers: institutions }); /* response without providers */
      } else setHealthCareProviders(response); /* response with providers */
    };

    if (isBlankString(debounceQuery)) {
      setHealthCareProviders(undefined);
    } else {
      getNewAddresses();
    }
  }, [props.includePhysicians, debounceQuery, makeRequest]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const isActive = (institutionId: number, addressId: number, physicianId?: number) => {
    return !!(
      active &&
      active.institution?.id === institutionId &&
      active.address?.id === addressId &&
      active.physician?.id === physicianId
    );
  };

  const getActiveFromProvider = (provider: Provider, addressIndex: number): Active | undefined => {
    if (provider.providerType === PROVIDER_TYPE.PHYSICIAN) {
      const address = provider.institution_addresses![addressIndex];
      if (!address) return undefined;
      return {
        institution: address.institution!,
        address: { ...address, index: addressIndex },
        physician: provider
      };
    }
    if (provider.providerType === PROVIDER_TYPE.INSTITUTION) {
      const address = provider.addresses[addressIndex];
      if (!address) return undefined;
      return {
        institution: provider,
        address: { ...address, index: addressIndex },
        physician: undefined
      };
    }
  };

  const getLastAddressIndex = (provider: Provider): number | undefined => {
    if (!provider) return undefined;
    if (provider.providerType === PROVIDER_TYPE.PHYSICIAN) {
      return provider.institution_addresses!.length - 1;
    }
    if (provider.providerType === PROVIDER_TYPE.INSTITUTION) {
      return provider.addresses.length - 1;
    }
  };

  const next = () => {
    if (!active?.institution && !active?.address) {
      const firstProvider = getActiveFromProvider(healthCareProviders!.providers![0], 0)!;
      return setActive({ ...firstProvider, providerIndex: 0 });
    }
    const newAddressIndex = active.address.index! + 1;
    const provider = getActiveFromProvider(healthCareProviders!.providers[active.providerIndex!], newAddressIndex);
    if (provider) {
      setActive({ ...provider, providerIndex: active.providerIndex });
    } else {
      const newProviderIndex = active.providerIndex! + 1;
      if (newProviderIndex === healthCareProviders!.providers.length) {
        setActive(undefined);
      } else {
        const newProvider = getActiveFromProvider(healthCareProviders!.providers[newProviderIndex], 0);
        if (newProvider) {
          return setActive({
            ...newProvider,
            providerIndex: newProviderIndex
          });
        }
      }
    }
  };

  const prev = () => {
    if (!active?.institution && !active?.institution) {
      const lastProviderIndex = healthCareProviders!.providers.length - 1;
      const lastProvider = healthCareProviders!.providers[lastProviderIndex];
      const lastAddressIndex = getLastAddressIndex(lastProvider);
      const provider = getActiveFromProvider(lastProvider, lastAddressIndex!);
      setActive({ ...provider!, providerIndex: lastProviderIndex! });
    } else {
      const previousAddressIndex = active.address.index! - 1;
      if (previousAddressIndex >= 0) {
        const { providerIndex } = active;
        const provider = getActiveFromProvider(healthCareProviders!.providers[providerIndex!], previousAddressIndex);
        if (provider) {
          setActive({ ...provider, providerIndex });
        }
      } else {
        const previousProviderIndex = active.providerIndex! - 1;
        if (previousProviderIndex < 0) {
          setActive(undefined);
        } else {
          const previousProvider = healthCareProviders!.providers[previousProviderIndex];
          if (previousProvider) {
            const lastAddressIndex = getLastAddressIndex(previousProvider);
            const provider = getActiveFromProvider(previousProvider, lastAddressIndex!);
            setActive({ ...provider!, providerIndex: previousProviderIndex });
          }
        }
      }
    }
  };

  const setInstitutionAddress = (institution: Institution, address: InstitutionAddress, physician?: Physician) => {
    props.onSelect(institution, address, physician);
    setQuery('');
    setActive(undefined);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { keyCode } = e;
    if (!healthCareProviders?.providers?.length) {
      return;
    }
    if (keyCode === 38) {
      prev();
      e.preventDefault();
    } else if (keyCode === 40) {
      next();
      e.preventDefault();
    } else if (keyCode === 13) {
      if (active?.institution && active.address) {
        setInstitutionAddress(active?.institution, active.address, active.physician);
      }
    } else {
      setActive(undefined);
    }
  };

  const getProviderAddresses = (provider: Provider) => {
    if (provider.providerType === PROVIDER_TYPE.INSTITUTION) {
      return provider.addresses;
    }
    if (provider.providerType === PROVIDER_TYPE.PHYSICIAN) {
      return provider.institution_addresses;
    }
  };

  if (props.hidden) return null;
  return (
    <div ref={ref}>
      <div className={`dropdown ${healthCareProviders?.providers.length ? 'is-active' : ''}`}>
        <Input
          maxWidth
          focusOnMount
          autoComplete='off'
          required
          iconName='search'
          value={query}
          onChange={onSearch}
          onKeyDown={onKeyDown}
          className='is-rounded'
          name='Institution'
          wrapperProps={{ style: { margin: 0 } }}
          placeholder={requestFormTFunction('physicianAndInstitutionPicker.searchInstitutionPlaceholderText')}
        />
        {!isBlankString(debounceQuery) && (
          <div className='dropdown-menu'>
            <div className='dropdown-content'>
              <div className='dropdown-content-container'>
                {loading ? (
                  <Text paragraph style={{ padding: '21px 36px' }}>
                    <Spinner size='small' />
                  </Text>
                ) : (
                  <>
                    {healthCareProviders &&
                    healthCareProviders.providers &&
                    healthCareProviders.providers.length > 0 ? (
                      healthCareProviders.providers.map((provider, index) => {
                        return getProviderAddresses(provider)?.map(
                          (address) =>
                            !props.excludeAddressIds?.includes(address.id) && (
                              <div key={`${provider.providerType}-${provider.id}-${address.id}`}>
                                <HealthCareProviderDropDownItem
                                  query={query}
                                  provider={provider}
                                  providerAddress={address}
                                  isActive={isActive}
                                  handleClick={setInstitutionAddress}
                                  providerIndex={index}
                                  scrollIntoView={
                                    index === active?.providerIndex || (!active?.institution && index === 0)
                                  }
                                  block={
                                    active?.providerIndex === index &&
                                    index + 1 === healthCareProviders.providers.length
                                      ? 'start'
                                      : 'nearest'
                                  }
                                />
                                <Divider />
                              </div>
                            )
                        );
                      })
                    ) : (
                      <Text paragraph color='grey-60' style={{ padding: '21px 36px' }}>
                        {requestFormTFunction('physicianAndInstitutionPicker.errors.noResultsFound', {
                          searchQuery: query
                        })}
                      </Text>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InstitutionAddressPicker;
