import { useCallback } from 'react';
import zxcvbn from 'zxcvbn';
import { useTranslate } from './useTranslate';

export const usePasswordStrengthCheck = () => {
  const { t, ready } = useTranslate('passwordInput');

  const checkPasswordStrength = useCallback(
    (password: string) => {
      if (!ready) return { ready, score: -1, warning: '', suggestions: [] };
      const { feedback, score } = zxcvbn(password);
      let warning = '';
      const warningKey = feedback.warning
        .replace(/[^a-zA-Z]/g, '')
        .toLowerCase()
        .replace(' ', '');
      const suggestions = feedback.suggestions.map((suggesstion) => {
        const suggestionKey = suggesstion
          .replace(/[^a-zA-Z]/g, '')
          .toLowerCase()
          .replace(' ', '');
        return t(`messages.${suggestionKey}` as any);
      }) as string[];
      if (feedback.warning) {
        warning = t(`messages.${warningKey}` as any);
      }

      return { score, warning, suggestions, ready };
    },
    [ready, t]
  );

  return { checkPasswordStrength };
};
