import { useContext, useEffect, useReducer, useState, useCallback } from 'react';
import moment from 'moment';
import { useQuery, useMutation } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from '../../../hooks/useTranslate';
import CustomStepper from '../../shared/Stepper';
import { AuthContext } from '../../../contexts/auth-context';
import { deepEqual, isEmptyArray, isNotUndefined } from '../../../utils';
import { LOCALSTORAGE, PARTNERED_INSTITUTIONS } from '../../../utils/constants';
import Skeleton from '../../shared/Skeleton';
import {
  CreateRequestReducerActions,
  CreateRequestReducerActionTypes,
  CreateRequestReducerState,
  HealthCareProvider,
  PersonalInformationType
} from '../../../types';
import InstitutionPhysicianPicker from './InstitutionPhysician';
import Authorization from './Authorization';
import PersonalInformation from './PersonalInformation';
import ReviewSummary from './ReviewSummary';
import apiService from '../../../services/api-service';
import { User } from '../../../types/common/IUser';
import VerifyEmail from '../../shared/VerifyEmail';
import UploadRecords from './UploadRecords';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '10vh',
    '&>div': {
      width: '95%'
    }
  },
  stepperLabelSkeleton: {
    height: '5rem',
    [theme.breakpoints.up('widescreen')]: {
      height: '2.5rem'
    }
  }
}));

// Initialize a constant containing the step titles and their corresponding step
export const STEP = {
  personalInformation: 0,
  emailVerification: 1,
  uploadRecords: 2,
  physicianInstitution: 3,
  authorization: 4,
  review: 5
};

function reducer(state: CreateRequestReducerState, action: CreateRequestReducerActions): CreateRequestReducerState {
  const { healthCareProviders } = state;
  let updatedHealthCareProviders: HealthCareProvider[];
  switch (action.type) {
    case CreateRequestReducerActionTypes.ADD_HEALTH_CARE_PROVIDER:
      updatedHealthCareProviders = [
        ...healthCareProviders,
        {
          institution: action.payload.institution,
          address: {
            ...action.payload.address,
            name: action.payload.address.name
              ? `${action.payload.institution.institution_name} – ${action.payload.address.name}`
              : action.payload.institution.institution_name
          },
          physician: action.payload.physician
        }
      ];
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.REMOVE_HEALTHCARE_PROVIDER:
      updatedHealthCareProviders = healthCareProviders.filter(
        (_, providerIndex) => providerIndex !== action.payload.index
      );
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.ADD_PHYSICIAN_TO_INSTITUTION:
      updatedHealthCareProviders = healthCareProviders.map((provider, index) => {
        if (action.payload.index === index) {
          return { ...provider, physician: action.payload.physician };
        }
        return { ...provider };
      });
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.REMOVE_PHYSICIAN_FROM_INSTITUTION:
      updatedHealthCareProviders = healthCareProviders.map((provider, index) => {
        if (action.payload.index === index) {
          return { ...provider, physician: undefined };
        }
        return { ...provider };
      });
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.ADD_NOTES_TO_REQUEST:
      updatedHealthCareProviders = healthCareProviders.map((provider, index) => {
        if (action.payload.index === index) {
          return { ...provider, notes: action.payload.notes };
        }
        return { ...provider };
      });
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.REMOVE_NOTES_FROM_REQUEST:
      updatedHealthCareProviders = healthCareProviders.map((provider, index) => {
        if (action.payload.index === index) {
          return { ...provider, notes: undefined };
        }
        return { ...provider };
      });
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.RESET:
      updatedHealthCareProviders = [];
      return { healthCareProviders: updatedHealthCareProviders };
    default:
      return state;
  }
}

const StepperLabelSkeleton = () => {
  const classes = useStyles();
  return <Skeleton className={classes.stepperLabelSkeleton} />;
};

export interface UserDataRequest {
  user: User;
  isVerified: boolean;
}

export interface PersonalInformationRequest {
  user: PersonalInformationType;
  institution_name: keyof typeof PARTNERED_INSTITUTIONS;
}

const NewRequestForm = () => {
  const classes = useStyles();
  const { institution, isLoggedIn, getPortalAccessForLoggedInUser, verifyEmailRequest } = useContext(AuthContext);
  const { t, ready: translationFileReady } = useTranslate('requestForm');

  const [verifyEmailLoading, setVerifyEmailLoading] = useState(false);
  const [verifyEmailError, setVerifyEmailError] = useState('');
  const [activeStep, setActiveStep] = useState(STEP.personalInformation);
  const [disabledSteps, setDisabledSteps] = useState<number[]>([]);
  const [signatureUrl, setSignatureUrl] = useState<string>();
  const [isConsentAuthorized, setIsConsentAuthorized] = useState<boolean>(false);
  const [confirmationTextId, setConfirmationTextId] = useState<number>();
  const [personalInformation, setPersonalInformation] = useState<PersonalInformationType>();
  const [memberUploads, setMemberUploads] = useState<File[]>([]);

  const {
    mutate: sendEmailVerification,
    error: sendEmailVerificationError,
    isLoading: sendEmailVerificationLoading
  } = useMutation(async (data: any) => {
    try {
      if (isLoggedIn) {
        const response = await apiService.post('/public/portal/user/verify-email', data, {
          Authorization: `Bearer ${localStorage.getItem(LOCALSTORAGE.publicAccessToken)}`,
          'Content-Type': 'application/json'
        });
        setVerifyEmailError('');
        return response;
      }

      const response = await apiService.post('/public/portal/verify-email', data);
      setVerifyEmailError('');
      setPersonalInformation({ ...data.user });
      return response;
    } catch (error) {
      console.error(error);
    }
  });

  const { data: loggedInUserData, refetch: refrechUserData } = useQuery<UserDataRequest>({
    queryKey: 'personal-form-ui',
    queryFn: () => {
      return apiService.get(`/user?include=address,insurance`);
    },
    enabled: isLoggedIn,
    cacheTime: 0,
    staleTime: 0
  });

  const [healthCareProvidersState, dispatch] = useReducer(reducer, {
    healthCareProviders: []
  });

  const updateMemberUploads = (files: File[]) => {
    setMemberUploads(files);
  };

  const handleConsentAuthorized = (isAuthorized: boolean, confirmationTextId: number) => {
    setIsConsentAuthorized(isAuthorized);
    setConfirmationTextId(confirmationTextId);
  };

  const handleNext = useCallback(
    (step?: number) => {
      setActiveStep((prevActiveStep) => {
        let newStep = prevActiveStep + 1;
        const newDisabledSteps = [...disabledSteps];
        // After Verifiying email disabled the ability to go to that step
        if (prevActiveStep === 1) {
          newDisabledSteps.push(STEP.emailVerification);
        }

        // If there are no health care providers chosen, skip the authorization step and disable it
        if (prevActiveStep === 3) {
          if (isEmptyArray(healthCareProvidersState.healthCareProviders)) {
            setSignatureUrl(undefined);
            setIsConsentAuthorized(false);
            newStep = prevActiveStep + 2;
            newDisabledSteps.push(STEP.authorization);
          }
        }

        setDisabledSteps(newDisabledSteps);
        // if a step is specified change to that step
        return step || newStep;
      });
    },
    [disabledSteps, healthCareProvidersState.healthCareProviders]
  );

  const updateUserRequest = (values: PersonalInformationType) => {
    return apiService.put('/user', {
      user: {
        ...values,
        phone: values.phone_number,
        address: {
          unit_number: values.extra_line_1,
          extra_line_1: values.extra_line_1,
          extra_line_2: 'values.extra_line_1',
          city: values.city,
          province: values.province,
          country: 'Canada',
          postal_code: values.postal_code,
          street_address: values.street_address
        }
      }
    });
  };

  const updateUserInsuranceDocuments = (values: PersonalInformationType) => {
    return apiService.post('/user/insurance_documents', {
      user: { insurance_number: values.insurance_number }
    });
  };

  const handlePersonalInformationSubmit = async (values: PersonalInformationType) => {
    const formattedDob = moment(values.dob, 'YYYY-MM-DD').format('YYYY-MM-DD');
    values.dob = formattedDob;

    try {
      // Handle Logged In Users
      if (isLoggedIn) {
        const personalInformationToSubmit: any = { ...values };
        personalInformationToSubmit.dob = formattedDob;

        if (loggedInUserData?.isVerified) {
          delete personalInformationToSubmit.first_name;
          delete personalInformationToSubmit.last_name;
          delete personalInformationToSubmit.email;
        }

        await updateUserInsuranceDocuments(personalInformationToSubmit);
        await updateUserRequest(personalInformationToSubmit);

        // If the current user making the request has verified his email skip the second step
        if (loggedInUserData?.user?.email_verified) {
          handleNext(STEP.uploadRecords);
          await getPortalAccessForLoggedInUser();
        } else {
          handleNext();
        }
      } else {
        // Remove access token if user making the request is unregistered
        localStorage.removeItem(LOCALSTORAGE.publicAccessToken);

        // If the personal information does not change do not resend an email
        // verification when moving from personal information step to verification email
        if (deepEqual(personalInformation, values)) {
          handleNext();
        } else {
          sendEmailVerification({
            user: values,
            institution_name: institution as keyof typeof PARTNERED_INSTITUTIONS
          });
          handleNext();
        }
      }

      if (isLoggedIn) {
        refrechUserData();
      }

      setPersonalInformation({ ...values });
    } catch (error) {
      console.error(error);
    }
  };

  const saveSignatureUrl = (url: string | undefined) => {
    setSignatureUrl(url);
  };

  const handleSendEmailVerification = () => {
    sendEmailVerification({
      user: personalInformation,
      institution_name: institution
    });
  };

  const verifyEmail = async (verifyEmailCode: string) => {
    setVerifyEmailLoading(true);

    try {
      await verifyEmailRequest({ verifyEmailCode: verifyEmailCode }, isLoggedIn);
      setVerifyEmailLoading(false);

      getPortalAccessForLoggedInUser();
      handleNext();
    } catch (error) {
      console.error(error);
      setVerifyEmailLoading(false);
      setVerifyEmailError(error as string);
    }
  };

  useEffect(() => {
    if (loggedInUserData?.user) {
      const { user } = loggedInUserData;
      const dob = user.dob ? moment(user.dob) : null;
      // Months are indexed by 0
      const dobMonth = dob ? (dob.month() + 1).toString() : '';
      const dobDay = dob ? dob.date().toString() : '';
      const dobYear = dob ? dob.year().toString() : '';
      setPersonalInformation({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        insurance_number: user?.insurance?.insurance_number || '',
        month: dobMonth,
        day: dobDay,
        year: dobYear,
        dob: dob ? dob.format('YYYY-MM-DD') : '',
        phone_number: user?.phone || '',
        street_address: user.address?.street_address || '',
        city: user.address?.city || '',
        province: user.address?.province || '',
        postal_code: user.address?.postal_code || '',
        extra_line_1: user.address?.extra_line_1 || ''
      });
    }
  }, [loggedInUserData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <div className={classes.root}>
      <CustomStepper
        disabledSteps={disabledSteps}
        steps={[
          {
            label: translationFileReady ? t('personalInformation.title') : <StepperLabelSkeleton />,
            ariaLabel: t('personalInformation.accessibleTitle'),
            disableGoingForward: activeStep === 0,
            content: (
              <PersonalInformation
                personalInformation={personalInformation}
                onSubmit={handlePersonalInformationSubmit}
                sendEmailVerificationError={sendEmailVerificationError as string}
                sendEmailVerificationLoading={sendEmailVerificationLoading}
                loggedInUserData={loggedInUserData}
              />
            )
          },
          {
            label: translationFileReady ? t('emailVerification.title') : <StepperLabelSkeleton />,
            ariaLabel: t('emailVerification.accessibleTitle'),
            disableGoingForward: isLoggedIn ? false : !localStorage.getItem(LOCALSTORAGE.publicAccessToken),
            content: (
              <VerifyEmail
                verifyEmail={verifyEmail}
                verifyEmailLoading={verifyEmailLoading}
                verifyEmailError={verifyEmailError}
                email={personalInformation?.email as string}
                sendEmailVerification={handleSendEmailVerification}
                sendEmailVerificationLoading={sendEmailVerificationLoading}
                sendEmailVerificationError={sendEmailVerificationError as string}
              />
            )
          },
          {
            label: translationFileReady ? t('uploadRecords.title') : <StepperLabelSkeleton />,
            ariaLabel: t('uploadRecords.accessibleTitle'),
            content: (
              <UploadRecords
                memberUploads={memberUploads}
                updateMemberUploads={updateMemberUploads}
                handleNext={handleNext}
              />
            )
          },
          {
            label: translationFileReady ? t('physicianInstitution.title') : <StepperLabelSkeleton />,
            ariaLabel: t('physicianInstitution.accessibleTitle'),
            content: (
              <InstitutionPhysicianPicker
                translationFileReady={translationFileReady}
                t={t}
                healthCareProviders={healthCareProvidersState.healthCareProviders}
                dispatch={dispatch}
                handleNext={handleNext}
              />
            )
          },
          {
            label: translationFileReady ? t('authorization.title') : <StepperLabelSkeleton />,
            ariaLabel: t('personalInformation.accessibleTitle'),
            disableGoingForward: !isNotUndefined(signatureUrl) || !isConsentAuthorized,
            content: (
              <Authorization
                signatureUrl={signatureUrl}
                saveSignatureUrl={saveSignatureUrl}
                handleNext={handleNext}
                isConsentAuthorized={isConsentAuthorized}
                handleConsentAuthorized={handleConsentAuthorized}
              />
            )
          },
          {
            label: translationFileReady ? t('review.title') : <StepperLabelSkeleton />,
            ariaLabel: t('review.accessibleTitle'),
            content: (
              <ReviewSummary
                updateDisabledSteps={setDisabledSteps}
                personalInformation={personalInformation}
                institutionData={healthCareProvidersState.healthCareProviders}
                signatureUrl={signatureUrl}
                confirmationTextId={confirmationTextId}
                loggedInUserData={loggedInUserData}
                memberUploads={memberUploads}
              />
            )
          }
        ]}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </div>
  );
};

export default NewRequestForm;
