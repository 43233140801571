import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Icon } from '@dothealth/dothealth-library';
import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Text from '../../shared/Typography';
import Skeleton from '../../shared/Skeleton';
import Container from '../../shared/Container';
import { useTranslate } from '../../../hooks/useTranslate';
import Button from '../../shared/Button';
import { isEmptyArray } from '../../../utils';
import { MAX_FILE_SIZE } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: '3rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  headingContainer: {
    alignSelf: 'flex-start'
  },
  pageContainer: {
    padding: '3.6rem 4rem',
    maxWidth: '55rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '2.4rem 1.8rem'
    }
  },
  titleText: {
    marginBottom: '1rem'
  },
  descriptionText: {
    maxWidth: '65rem'
  },
  buttonContainer: {
    marginTop: '2rem',
    minWidth: '1.6rem',
    minHeight: '5rem',
    alignSelf: 'flex-end'
  },
  button: {
    marginBottom: '1rem'
  },
  titleSkeleton: {
    width: '27.7rem',
    height: '2.5rem',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '39rem'
    }
  },
  descriptionSkeleton: {
    width: '26.1rem',
    height: '18.6rem',
    [theme.breakpoints.up('xs')]: {
      width: '38.6rem',
      height: '11.4rem'
    }
  },
  dropZoneContainer: {
    width: '100%',
    marginTop: '5rem'
  },
  dropzoneTextContainer: {
    minHeight: '25rem',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem'
  },
  dragDropDescription: {
    maxWidth: '40rem'
  },
  errorContainer: {
    marginTop: '2rem',
    marginLeft: '4rem'
  },
  filesContainer: {
    marginTop: '5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem'
  },
  fileListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: '20px',
    padding: '15px 37px 15px 15px',
    border: '2px solid #EBEEFB',
    borderRadius: '12px',
    maxWidth: '48rem'
  },
  rejectedFileText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  invalidFile: {
    border: '2px solid #EF1E2B'
  },
  closeIcon: {
    marginLeft: 'auto',
    cursor: 'pointer'
  }
}));

interface UploadRecordsProps {
  memberUploads: File[];
  updateMemberUploads: (files: File[]) => void;
  handleNext: () => void;
}

interface FileErrors {
  code: string;
  message: string;
}
interface RejectedFiles {
  errors: FileErrors[];
  file: File;
}

const LabelSkeleton = () => <Skeleton height='2.5rem' width='25rem' />;

const baseStyle = {
  width: '100%',
  border: '2px dashed #672BC2',
  borderColor: '#672BC2',
  backgroundColor: 'rgb(239, 241, 253)',
  padding: '12px 24px',
  color: '#EBEBEB',
  borderRadius: '40px',
  cursor: 'pointer'
};

const acceptStyle = {
  borderColor: '#12CC9F'
};

const rejectStyle = {
  borderColor: '#EF1E2B'
};

const UploadRecords = ({ memberUploads, updateMemberUploads, handleNext }: UploadRecordsProps) => {
  const classes = useStyles();
  const [rejectedFiles, setRejectedFiles] = useState<RejectedFiles[]>([]);

  const { t: requestFormTFunction, ready: isRequestFormTranslationReady } = useTranslate('requestForm');

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'application/pdf': ['.pdf'],
      'application/xml': ['.xml'],
      'text/csv': ['.csv'],
      'application/zip': ['.zip'],
      'text/plain': ['.plain']
    },
    onDrop(acceptedFiles, fileRejections) {
      if (acceptedFiles.length > 0) {
        updateMemberUploads([...memberUploads, ...acceptedFiles]);
      }
      if (fileRejections.length > 0) {
        setRejectedFiles(fileRejections);
      } else {
        setRejectedFiles([]);
      }
    },
    maxSize: MAX_FILE_SIZE // Max file size is 20MB
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragAccept, isDragReject]
  );

  const removeFileByName = (e: { stopPropagation: () => void }, fileName: string) => {
    e.stopPropagation();
    const filteredFiles = memberUploads.filter((file) => file.name !== fileName);
    updateMemberUploads(filteredFiles);
  };

  return (
    <Container className={classes.contentContainer}>
      <div className={classes.headingContainer}>
        <Text h2 color='ship-cove' className={classes.titleText}>
          {isRequestFormTranslationReady ? (
            `${requestFormTFunction('uploadRecords.title')} (${requestFormTFunction('optional')})`
          ) : (
            <Skeleton />
          )}
        </Text>
        <Text paragraph color='ship-cove' className={classes.descriptionText}>
          {isRequestFormTranslationReady ? requestFormTFunction('uploadRecords.description') : <LabelSkeleton />}
        </Text>
      </div>
      <div className={classes.dropZoneContainer}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <div className={classes.dropzoneTextContainer}>
            <Text fontWeight={500} h5 color='grey-40'>
              {isRequestFormTranslationReady ? requestFormTFunction('uploadRecords.dragDropTitle') : <Skeleton />}
            </Text>
            <Text paragraph color='purple' underlined>
              {isRequestFormTranslationReady ? requestFormTFunction('uploadRecords.dragDropSubtitle') : <Skeleton />}
            </Text>
            <Text paragraph color='grey-60' className={classes.dragDropDescription}>
              {isRequestFormTranslationReady ? (
                requestFormTFunction('uploadRecords.fileFormatsDescription')
              ) : (
                <Skeleton />
              )}
            </Text>
            <Text paragraph color='ship-cove' className={classes.descriptionText}>
              {isRequestFormTranslationReady ? requestFormTFunction('uploadRecords.compressNotice') : <Skeleton />}
            </Text>
            <Text paragraph color='ship-cove' className={classes.descriptionText}>
              {isRequestFormTranslationReady ? requestFormTFunction('uploadRecords.removeExeNotice') : <Skeleton />}
            </Text>
          </div>
        </div>
      </div>

      {rejectedFiles && rejectedFiles.length > 0 && (
        <div className={classes.filesContainer}>
          <Text h6 color='error'>
            {requestFormTFunction('uploadRecords.invalidFiles')}
          </Text>
          {rejectedFiles.map(({ file, errors }, index) => (
            <>
              <div className={clsx(classes.fileListItem, classes.invalidFile)} key={file.name + index}>
                <Icon name='file-purple' size='regular' ariaLabel='purple file' />
                <Text paragraph color='grey-40'>
                  {file.name}
                </Text>
              </div>
              {errors.map((error) => {
                if (error.code === 'file-invalid-type') {
                  return (
                    <Text paragraph color='error'>
                      {requestFormTFunction('uploadRecords.fileInvalidType')}
                    </Text>
                  );
                }
                if (error.code === 'file-too-large') {
                  return (
                    <Text paragraph color='error'>
                      {requestFormTFunction('uploadRecords.fileTooLarge')}
                    </Text>
                  );
                }
                return null;
              })}
            </>
          ))}
        </div>
      )}
      <div className={classes.filesContainer}>
        {memberUploads &&
          memberUploads.length > 0 &&
          memberUploads.map((file, index) => (
            <div className={classes.fileListItem} key={file.name + index}>
              <Icon name='file-purple' size='regular' ariaLabel='purple file' />
              <Text paragraph color='grey-40'>
                {file.name}
              </Text>
              <Icon
                className={classes.closeIcon}
                name='close-grey'
                onClick={(e: React.MouseEvent<Element, MouseEvent>) => removeFileByName(e, file.name)}
                ariaLabel='close'
              />
            </div>
          ))}
      </div>

      <div className={classes.buttonContainer}>
        {isRequestFormTranslationReady ? (
          <Button
            style={{ minWidth: '24rem' }}
            type='button'
            label={isEmptyArray(memberUploads) ? requestFormTFunction('skip') : requestFormTFunction('continue')}
            onClick={() => handleNext()}
          />
        ) : (
          <Skeleton width='10rem' height='4.6rem' />
        )}
      </div>
    </Container>
  );
};

export default UploadRecords;
