import { useState } from 'react';
import { isValidEmail, isValidPhoneNumber, isValidPostalCode } from '../../../../../utils';
import { useForm, SubmitFunction, ValidationErrors } from '../../../../../hooks/useForm';
import { Institution, InstitutionAddress, Physician } from '../../../../../types';
import PageContainer from '../../../../shared/PageContainer';
import CreateInstitutionFormUI from './CreateInstitutionFormUI';
import { useTranslate } from '../../../../../hooks/useTranslate';
import Icon from '../../../../shared/Icon';
import Container from '../../../../shared/Container';
import IconButton from '../../../../shared/IconButton';
import { useMutation } from '../../../../../hooks/useQuery';

export const CREATE_INSTITUTION_INITIAL_VALUES = {
  institution_name: '',
  name: '',
  address: '',
  city: '',
  province: '',
  country: '',
  postal_code: '',
  phone_number: '',
  fax: '',
  reference_key: '',
  locale_id: '',
  email: ''
};

export interface CreateInstitutionRequest {
  institution: {
    institution_name: string;
    name: string;
    address: string;
    city: string;
    province: string;
    country: string;
    postal_code: string;
    phone_number: string;
    fax: string;
    email: string;
  };
}

export type CreateProviderIntitalStateValues = typeof CREATE_INSTITUTION_INITIAL_VALUES;

export interface CreateInstitutionFormProps {
  open: boolean;
  onClose: () => void;
  setInstitutionAddress: (
    institution: Institution,
    institutionAddress: InstitutionAddress,
    physician?: Physician
  ) => any;
}

const CreateInstitutionForm = ({ onClose, setInstitutionAddress }: CreateInstitutionFormProps) => {
  const { t: errorsT } = useTranslate('formErrors');
  const [showForm, setShowForm] = useState(false);

  const {
    loading: isCreatingInstitutionLoading,
    makeRequest: createInstitution,
    error: errorWhileCreatingInstitution
  } = useMutation<CreateInstitutionRequest>('POST');

  const { handleSubmit, resetForm, getFieldProps } = useForm(CREATE_INSTITUTION_INITIAL_VALUES, {
    validate: (values) => {
      const errors: ValidationErrors<typeof CREATE_INSTITUTION_INITIAL_VALUES> = {};

      if (!values.institution_name) {
        errors.institution_name = errorsT('invalidInstitutionName');
      }
      if (!values.phone_number || !isValidPhoneNumber(values.phone_number)) {
        errors.phone_number = errorsT('invalidPhoneNumber');
      }
      if (!values.address) {
        errors.address = errorsT('invalidStreetAddress');
      }
      if (!values.postal_code || !isValidPostalCode(values.postal_code)) {
        errors.postal_code = errorsT('invalidPostalCode');
      }
      if (!values.province) {
        errors.province = errorsT('invalidProvince');
      }
      if (!values.city) {
        errors.city = errorsT('invalidCity');
      }
      if (values.email && !isValidEmail(values.email)) {
        errors.email = errorsT('invalidEmail');
      }
      return errors;
    }
  });

  const onSubmit: SubmitFunction<CreateProviderIntitalStateValues> = (
    values: typeof CREATE_INSTITUTION_INITIAL_VALUES
  ) => {
    createInstitution(`/public/portal/institutions`, {
      institution: {
        institution_name: values.institution_name,
        name: values.name,
        address: values.address,
        city: values.city,
        province: values.province,
        country: values.country,
        postal_code: values.postal_code,
        fax: values.fax,
        email: values.email,
        phone_number: values.phone_number
      }
    })
      .then((resp) => {
        setInstitutionAddress(resp.institution, resp.institution.addresses[0]);
        resetForm();
      })
      .catch((err) => console.error(err))
      .finally(() => {
        onClose();
      });
  };

  const toggleFormUI = () => {
    if (showForm) {
      setShowForm(false);
      resetForm();
      onClose();
    } else {
      setShowForm(true);
    }
  };

  return (
    <>
      <Container isFlex justifyContent='center' margin='3rem'>
        <IconButton label='Toggle create institution UI' onClick={toggleFormUI}>
          <Icon size='small' name='caret-down' />
        </IconButton>
      </Container>
      {showForm && (
        <PageContainer style={{ margin: '0 0 2rem 0' }}>
          <CreateInstitutionFormUI
            onSubmit={handleSubmit(onSubmit)}
            getFieldProps={getFieldProps}
            submitLoading={isCreatingInstitutionLoading}
            submitError={errorWhileCreatingInstitution}
          />
        </PageContainer>
      )}
    </>
  );
};

export default CreateInstitutionForm;
