import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmptyObject, isNotEmptyArray, isNotUndefined } from '../utils';

export type ValidationErrors<T> = Partial<Record<keyof T, string>>;
export type ValidationFunction<T> = (values: T) => ValidationErrors<T>;

interface SubmitFunctionContext {
  setIsFormSubmitting: (isSubmitting: boolean) => void;
}
export type SubmitFunction<T> = (values: T, context: SubmitFunctionContext) => void | Promise<void>;
export type ValidationMode = 'onChange' | 'onBlur' | 'onSubmit' | 'onBlurAndChange';
export type DirtyFields<T> = Partial<Record<keyof T, boolean>>;
export type TouchedFields<T> = Partial<Record<keyof T, boolean>>;
export type DirtyAndBluredFieldsRef<T> = Partial<Record<keyof T, boolean>>;

interface SetValueFunctionOptions {
  shouldValidate: boolean;
}

export type SetValueFunction<T> = <K extends keyof T>(key: K, value: T[K], options?: SetValueFunctionOptions) => void;
type SetValuesFunction<T> = (
  values: Partial<T>,
  options?: {
    shouldValidate?: boolean;
  }
) => void;
export type ValidateFieldFunction<T> = <K extends keyof T>(key: K, formState: T) => void;
type SetErrorFunction<T> = <K extends keyof T>(key: K, errorMessage: string) => void;
type RemoveErrorFunction<T> = <K extends keyof T>(key: K) => void;
type HandleSubmitFunction<T> = (submitFunction: SubmitFunction<T>) => (e: React.FormEvent<HTMLFormElement>) => void;
type GetFieldValueFunction<T> = <K extends keyof T>(key: K) => T[K];
type GetFieldErrorFunction<T> = <K extends keyof T>(key: K) => string | undefined;
type IsTouchedFieldFunction<T> = <K extends keyof T>(key: K) => boolean;
type IsDirtyFieldFunction<T> = <K extends keyof T>(key: K) => boolean;
type SetFieldTouchedFunction<T> = <K extends keyof T>(key: K) => void;
type SetFocusFunction<T> = <K extends keyof T>(key: K) => void;

interface FieldRef<T extends HTMLElement> {
  ref: Refs<T>;
}

type GetFieldRefFunction<T> = <E extends HTMLElement>(key: keyof T) => FieldRef<E>;

type InputFields<E extends HTMLElement = any> = E extends undefined
  ? HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  : E;

type Refs<T> = React.Ref<T> | React.MutableRefObject<T>;

interface FieldProps<T, E extends HTMLElement, K extends keyof T> {
  name: K;
  onChange: (e: React.ChangeEvent<InputFields<E>>) => void;
  onBlur: (e: React.FocusEvent<InputFields<E>>) => void;
  onFocus: (e: React.FocusEvent<InputFields<E>>) => void;
  error: string | undefined;
  value: T[K];
  ref: Refs<E>;
  'aria-invalid'?: boolean;
}

interface GetFieldPropsFunctionOptions<E extends HTMLElement = any> {
  onBlur?: (e: React.FocusEvent<InputFields<E>>) => void;
  onChange?: (e: React.ChangeEvent<InputFields<E>>) => void;
  onFocus?: (e: React.FocusEvent<InputFields<E>>) => void;
}

export type GetFieldPropsFunction<T> = <E extends HTMLElement, K extends keyof T = keyof T>(
  key: K,
  options?: GetFieldPropsFunctionOptions<E>
) => FieldProps<T, E, K>;

interface UseFormOptions<T> {
  validate?: ValidationFunction<T>;
  validationMode?: ValidationMode;
  shouldFocusError?: boolean;
}

const defaultFormOptions: UseFormOptions<any> = {
  validate: () => ({}),
  validationMode: 'onBlurAndChange',
  shouldFocusError: true
};

export const useForm = <T>(initialValues: T, defaultOptions: UseFormOptions<T> = defaultFormOptions) => {
  const [formState, setFormState] = useState(initialValues);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<T>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fieldToFocusOn, setFieldToFocusOn] = useState<keyof T>();

  // An object which tracks which fields were changed.
  const dirtyFieldsRef = useRef<DirtyFields<T>>({});

  // An object which tracks which fields were touched, i.e. Focussed on or their state was changed.
  const touchedFieldsRef = useRef<TouchedFields<T>>({});

  // An object which tracks which fields were touched and blured, i.e. Their state was changed and focussed out after.
  const dirtyAndBluredFieldsRef = useRef<DirtyAndBluredFieldsRef<T>>({});

  const options = useMemo(() => {
    return { ...defaultFormOptions, ...defaultOptions };
  }, [defaultOptions]);

  const initialValuesRef = useRef(initialValues);

  const shouldFocusError = useMemo(() => options.shouldFocusError, [options.shouldFocusError]);

  const [currentValidationMode, setCurrentValidationMode] = useState(options.validationMode);

  const hasErrors = useMemo(() => {
    return !isEmptyObject(validationErrors);
  }, [validationErrors]);

  useEffect(() => {
    setCurrentValidationMode(options.validationMode);
  }, [options.validationMode]);

  const validationFunction = useCallback<ValidationFunction<T>>((args) => options.validate!(args), [options.validate]);

  const setFieldTouched = useCallback<SetFieldTouchedFunction<T>>((key) => {
    touchedFieldsRef.current = { ...touchedFieldsRef.current, [key]: true };
  }, []);

  const setError = useCallback<SetErrorFunction<T>>((key, message) => {
    setValidationErrors((previous) => {
      return { ...previous, [key]: message };
    });
  }, []);

  const removeFieldError = useCallback<RemoveErrorFunction<T>>((key) => {
    setValidationErrors((previous) => {
      const newErrors = previous;
      delete newErrors[key];
      return newErrors;
    });
  }, []);

  const getFieldError = useCallback<GetFieldErrorFunction<T>>(
    (key) => {
      return validationErrors[key];
    },
    [validationErrors]
  );

  const resetErrors = useCallback(() => {
    setValidationErrors({});
  }, []);

  const validateField = useCallback<ValidateFieldFunction<T>>(
    (key, formState) => {
      const allErrors = validationFunction(formState);
      const errorAtField = allErrors[key] as string;
      if (errorAtField) {
        setError(key, errorAtField);
      } else {
        removeFieldError(key);
      }
    },
    [removeFieldError, setError, validationFunction]
  );

  const getFieldValue = useCallback<GetFieldValueFunction<T>>(
    (key) => {
      const value = formState[key];
      return value;
    },
    [formState]
  );

  const calculateFieldToFocusOn = useCallback(
    (errors) => {
      if (shouldFocusError) {
        if (errors && isNotEmptyArray(Object.keys(errors))) {
          const firstFieldWithError = Object.keys(errors)[0];
          setFieldToFocusOn(firstFieldWithError as any);
        } else {
          setFieldToFocusOn(undefined);
        }
      }
    },
    [shouldFocusError]
  );

  const setValue = useCallback<SetValueFunction<T>>(
    (key, value, options = { shouldValidate: false }) => {
      setFieldTouched(key);
      dirtyFieldsRef.current = { ...dirtyFieldsRef.current, [key]: true };
      setFormState((previous) => {
        const newFormsState = { ...previous, [key]: value };
        if (options.shouldValidate) {
          validateField(key, newFormsState);
        }
        return newFormsState;
      });
      setFieldToFocusOn(undefined);
    },
    [setFieldTouched, validateField]
  );

  const setValues = useCallback<SetValuesFunction<T>>(
    (values, options = { shouldValidate: false }) => {
      setFormState((previous) => {
        const newFormState = { ...previous, ...values };
        if (options.shouldValidate) {
          const errors = validationFunction(newFormState);
          setValidationErrors(errors);
          calculateFieldToFocusOn(errors);
        }
        return newFormState;
      });
    },
    [validationFunction, calculateFieldToFocusOn]
  );

  const resetValues = useCallback(() => {
    dirtyFieldsRef.current = {};
    touchedFieldsRef.current = {};
    setIsSubmitting(false);
    setFormState(initialValuesRef.current);
  }, []);

  const resetHelpers = useCallback(() => {
    setCurrentValidationMode(options.validationMode);
    setFieldToFocusOn(undefined);
  }, [options.validationMode]);

  const resetForm = useCallback(() => {
    resetHelpers();
    resetErrors();
    resetValues();
  }, [resetErrors, resetValues, resetHelpers]);

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [resetForm]);

  const validateForm = useCallback(() => {
    const errors = validationFunction(formState);
    setValidationErrors((previous) => ({ ...previous, ...errors }));
    calculateFieldToFocusOn(errors);
    return errors;
  }, [calculateFieldToFocusOn, formState, validationFunction]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<InputFields>) => {
      event.persist();
      const { name, value } = event.target;
      const shouldValidate =
        currentValidationMode === 'onChange' ||
        (currentValidationMode === 'onBlurAndChange' && !!dirtyAndBluredFieldsRef.current[name]);
      setValue(name as any, value as any, { shouldValidate });
    },
    [setValue, currentValidationMode]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<InputFields>) => {
      const { name, value } = e.target;
      const shouldValidate =
        currentValidationMode === 'onBlur' ||
        (currentValidationMode === 'onBlurAndChange' &&
          dirtyFieldsRef.current[name] &&
          !dirtyAndBluredFieldsRef.current[name]);
      if (shouldValidate) {
        validateField(name as any, { [name]: value } as any);
      }
      if (dirtyFieldsRef.current[name] && !dirtyAndBluredFieldsRef.current[name]) {
        dirtyAndBluredFieldsRef.current = { ...dirtyAndBluredFieldsRef.current, [name]: true };
      }
    },
    [currentValidationMode, validateField]
  );

  const handleFocus = useCallback((e: React.FocusEvent<InputFields>) => {
    const { name } = e.target;
    touchedFieldsRef.current = { ...touchedFieldsRef.current, [name]: true };
  }, []);

  const isTouchedField = useCallback<IsTouchedFieldFunction<T>>((key) => {
    return !!touchedFieldsRef.current[key];
  }, []);

  const isDirtyfield = useCallback<IsDirtyFieldFunction<T>>((key) => {
    return !!dirtyFieldsRef.current[key];
  }, []);

  const getFieldRefFunction = useCallback<GetFieldRefFunction<T>>(
    (key) => {
      if (fieldToFocusOn === key) {
        return {
          ref: (element: any) => {
            element?.focus();
            return element;
          }
        } as any;
      }
    },
    [fieldToFocusOn]
  );

  const handleSubmit = useCallback<HandleSubmitFunction<T>>(
    (submitFunction) => {
      return (e) => {
        e.preventDefault();
        setFieldToFocusOn(undefined);
        setIsSubmitting(true);
        const errors = validateForm();
        if (!isEmptyObject(errors)) {
          setCurrentValidationMode('onChange');
          setIsSubmitting(false);
          return;
        }
        submitFunction(formState, { setIsFormSubmitting: setIsSubmitting });
      };
    },
    [validateForm, formState]
  );

  /**
   * A wrapper function to manage the props passed to Input component in an easier manner
   * Manages the value, focus and change events, error state, accessibility props for a given field
   */
  const getFieldProps = useCallback<GetFieldPropsFunction<T>>(
    (key, options = {}) => {
      return {
        onChange: (e) => {
          handleChange(e);
          if (isNotUndefined(options.onChange)) {
            options.onChange(e);
          }
        },
        onBlur: (e) => {
          handleBlur(e);
          if (isNotUndefined(options.onBlur)) {
            options.onBlur(e);
          }
        },
        onFocus: (e) => {
          handleFocus(e);
          if (isNotUndefined(options.onFocus)) {
            options.onFocus(e);
          }
        },
        error: getFieldError(key),
        name: key,
        value: getFieldValue(key),
        'aria-invalid': !!getFieldError(key),
        ...getFieldRefFunction(key as any)
      };
    },
    [getFieldError, getFieldValue, handleBlur, handleChange, handleFocus, getFieldRefFunction]
  );

  const setFocus = useCallback<SetFocusFunction<T>>((key) => {
    setFieldToFocusOn(key);
  }, []);

  return {
    values: formState,
    errors: validationErrors,
    getFieldProps,
    fieldToFocusOn,
    isTouchedField,
    isDirtyfield,
    setFieldTouched,
    setError,
    setFocus,
    hasErrors,
    isSubmitting,
    resetValues,
    resetErrors,
    resetForm,
    handleSubmit,
    handleBlur,
    handleFocus,
    validateField,
    handleChange,
    setValue,
    setValues
  };
};
