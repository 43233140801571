import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { SetStateAction, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../../../hooks/useRoutes';
import { useTranslate } from '../../../../hooks/useTranslate';
import Divider from '../../../shared/Divider';
import Spinner from '../../../shared/Spinner';
import Text from '../../../shared/Typography';
import Container from '../../../shared/Container';
import { HealthCareProvider, PersonalInformationType } from '../../../../types';
import PageContainer from '../../../shared/PageContainer';
import Skeleton from '../../../shared/Skeleton';
import { generateKey, isEmptyArray } from '../../../../utils';
import Button from '../../../shared/Button';
import useQuery from '../../../../hooks/useQuery';
import {
  DOT_HEALTH_PRIVACY_POLICY_URL,
  DOT_HEALTH_TERMS_URL,
  MAX_FILE_COUNT,
  MAX_FILE_SIZE
} from '../../../../utils/constants';
import { AuthContext } from '../../../../contexts/auth-context';
import { UserDataRequest, STEP } from '..';

// eslint-disable-next-line
const dataURLtoBlob = require('blueimp-canvas-to-blob');

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: '3rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headingContainer: {
    alignSelf: 'flex-start'
  },
  pageContainer: {
    width: '100%',
    maxWidth: '54.5rem',
    padding: '3rem 3.6rem',
    marginTop: '4rem'
  },
  sectionContainer: {
    marginBottom: '3.5rem'
  },
  titleText: {
    marginBottom: '1rem'
  },
  descriptionText: {
    maxWidth: '81rem'
  },
  titleSkeleton: {
    height: '3.2rem',
    width: '30rem'
  },
  descriptionSkeleton: {
    width: '27rem',
    height: '4.8rem',
    [theme.breakpoints.up('xs')]: {
      width: '46rem',
      height: '2.4rem'
    }
  },
  divider: {
    marginBottom: '2rem',
    marginTop: '7px'
  },
  personalInformationTable: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: '2rem',
      gridRowGap: '1rem',
      gridAutoRows: '0.7fr'
    }
  },
  labeledText: {
    width: '100%',
    margin: '1rem 0',
    [theme.breakpoints.up('xs')]: {
      margin: '0'
    }
  },
  label: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '1.5rem',
    color: theme.palette.grayscale['60'],
    fontWeight: 300
  },
  paragraph: {
    fontSize: '15px',
    fontWeight: 500,
    color: theme.palette.grayscale['30'],
    textAlign: 'left',
    marginTop: '10px',
    overflowWrap: 'break-word'
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '2rem 0'
  },
  signatureImg: {
    maxWidth: '23rem',
    height: '100%'
  },
  buttonContainer: {
    minWidth: '1.6rem',
    minHeight: '5rem',
    margin: '0 auto'
  },
  memberUploadList: {
    paddingLeft: '2rem'
  }
}));

type LabeledTextProps = {
  label: string;
  text: string | JSX.Element;
  showSkeleton?: boolean;
};

type RequestSummaryProps = {
  personalInformation?: PersonalInformationType;
  institutionData?: HealthCareProvider[];
  signatureUrl?: string;
  confirmationTextId?: number;
  loggedInUserData?: UserDataRequest;
  memberUploads?: File[];
  updateDisabledSteps: (value: SetStateAction<number[]>) => void;
};

const LabeledText = ({ label, text }: LabeledTextProps) => {
  const classes = useStyles();

  return (
    <div className={classes.labeledText}>
      <p className={clsx(classes.label)}>{label}</p>
      <p className={classes.paragraph}>{text}</p>
    </div>
  );
};

const splitArrayBySize = (array: File[], maxSize: number): File[][] => {
  const result: File[][] = [];
  let currentChunk: File[] = [];

  for (const item of array) {
    if (currentChunk.length === 0) {
      currentChunk.push(item);
    } else {
      const currentSize = currentChunk.reduce((size, file) => size + file.size, 0);
      const itemSize = item.size;

      if (currentSize + itemSize <= maxSize && currentChunk.length < MAX_FILE_COUNT) {
        currentChunk.push(item);
      } else {
        result.push(currentChunk);
        currentChunk = [item];
      }
    }
  }

  if (currentChunk.length > 0) {
    result.push(currentChunk);
  }

  return result;
};

const RequestSummary = ({
  personalInformation,
  institutionData,
  signatureUrl,
  confirmationTextId,
  memberUploads,
  updateDisabledSteps
}: RequestSummaryProps) => {
  const history = useHistory();
  const { routesTFunction } = useRoutes();
  const classes = useStyles();
  const { institution } = useContext(AuthContext);
  const { t: requestFormTFunction, ready: isRequestFormTranslationReady } = useTranslate('requestForm');
  const [disableRequestSubmit, setDisableRequestSubmit] = useState(false);
  const [failedUploadChunks, setFailedUploadChunks] = useState<File[][]>([]);
  const [resourceId, setResourceId] = useState<string | undefined>();

  const {
    loading: informationRequestLoading,
    makeRequest,
    error: informationRequestError
  } = useQuery<any>('/public/portal/information_requests', 'FORM_DATA');

  const {
    loading: memberUploadLoading,
    makeRequest: makeRequestMemberUpload,
    error: memberUploadError
  } = useQuery<any>('/public/portal/upload', 'FORM_DATA');

  if (!isRequestFormTranslationReady) return <Spinner withSpacing />;

  const resetFailedUploads = () => {
    setFailedUploadChunks([]);
  };

  // eslint-disable-next-line
  const fullAddress = `${personalInformation?.street_address} ${personalInformation?.extra_line_1}  ${personalInformation?.city} ${personalInformation?.province}, ${personalInformation?.postal_code}`;

  const formData = {
    'user_data[fullName]': `${personalInformation?.first_name} ${personalInformation?.last_name}`,
    'user_data[HCN]': `${personalInformation?.insurance_number}`,
    'user_data[dob]': `${personalInformation?.dob}`,
    'user_data[phone]': `${personalInformation?.phone_number}`,
    'user_data[fullAddress]': fullAddress,
    'user_data[email]': personalInformation?.email
  };

  const submitInformationRequest = async () => {
    if (signatureUrl && institutionData && confirmationTextId && !disableRequestSubmit) {
      const blob = dataURLtoBlob(signatureUrl);

      const institutions = institutionData.reduce((a, v, i) => {
        const newInstitutionList = { ...a, [`institutions[${i}][institution_address_id]`]: v.address.id };

        if (v?.notes) {
          newInstitutionList[`institutions[${i}][notes]`] = v?.notes;
        }

        if (v?.physician?.id) {
          newInstitutionList[`institutions[${i}][physician_id]`] = v?.physician?.id;
        }

        return newInstitutionList;
      }, {});

      await makeRequest(`/public/portal/information_requests`, {
        file: blob,
        fileField: 'signature',
        formdata: {
          ...institutions,
          ...formData,
          confirmation_text_id: confirmationTextId
        }
      });

      setDisableRequestSubmit(true);
    }
  };

  const uploadFailedChunks = async (resourceId: string, failedUploadChunks: File[][]) => {
    // If resource ID exist from previous request, upload the rest of the failed uploads to that resource group
    const uploadPromises: Promise<void>[] = failedUploadChunks.map((chunk) => {
      return makeRequestMemberUpload(`/public/portal/resources/${resourceId}/upload`, {
        file: chunk,
        fileField: 'files',
        formData: formData
      }).catch((error) => {
        if (chunk) {
          setFailedUploadChunks((prev) => [...prev, chunk]);
        }
        throw error; // Re-throw the error to propagate it to the outer try-catch block
      });
    });

    try {
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error('error', error);
      throw error; // Re-throw the error to propagate it to the outer try-catch block
    }
  };

  const uploadChunks = async () => {
    if (memberUploads && memberUploads.length > 0) {
      const uploadChunks: File[][] = splitArrayBySize(memberUploads, MAX_FILE_SIZE);
      /**
       * Remove the first chunk to send an initial request and fetch a resource id to upload the rest to
       */
      const firstChunk = uploadChunks.shift();

      try {
        const { resource } = await makeRequestMemberUpload('/public/portal/upload', {
          file: firstChunk,
          fileField: 'files',
          formdata: formData
        }).catch((error) => {
          if (firstChunk) {
            setFailedUploadChunks((prev) => [...prev, firstChunk]);
          }
          throw error; // Re-throw the error to propagate it to the outer try-catch block
        });

        if (resource && resource.id) {
          setResourceId(resource.id);

          if (uploadChunks.length > 0) {
            const uploadPromises: Promise<void>[] = uploadChunks.map((chunk) => {
              return makeRequestMemberUpload(`/public/portal/resources/${resource.id}/upload`, {
                file: chunk,
                fileField: 'files',
                formData: {
                  'user_data[fullName]': `${personalInformation?.first_name} ${personalInformation?.last_name}`,
                  'user_data[HCN]': `${personalInformation?.insurance_number}`,
                  'user_data[dob]': `${personalInformation?.dob}`,
                  'user_data[phone]': `${personalInformation?.phone_number}`,
                  // eslint-disable-next-line
                  'user_data[fullAddress]': `${personalInformation?.street_address} ${personalInformation?.extra_line_1} ${personalInformation?.city} ${personalInformation?.province}, ${personalInformation?.postal_code}`,
                  'user_data[email]': personalInformation?.email
                }
              }).catch((error) => {
                if (chunk) {
                  setFailedUploadChunks((prev) => [...prev, chunk]);
                }
                throw error; // Re-throw the error to propagate it to the outer try-catch block
              });
            });

            await Promise.all(uploadPromises);
          }
        }
      } catch (error) {
        console.error('error', error);
        throw error; // Re-throw the error to propagate it to the outer try-catch block
      }
    }
  };

  const handleSubmitInformationRequest = async () => {
    let initialDisabledSteps: number[] = [];

    updateDisabledSteps((prevSteps: number[]) => {
      initialDisabledSteps = [...prevSteps];
      return [
        STEP.personalInformation,
        STEP.emailVerification,
        STEP.uploadRecords,
        STEP.physicianInstitution,
        STEP.authorization,
        STEP.review
      ];
    });

    try {
      resetFailedUploads();

      await submitInformationRequest();
      if (resourceId && failedUploadChunks.length > 0) {
        await uploadFailedChunks(resourceId, failedUploadChunks);
      } else {
        await uploadChunks();
      }

      history.push(routesTFunction('routerPaths./request/successful'));
    } catch (error) {
      console.error('error', error);
      // Reset disabled steps to its initial state if there is an error
      updateDisabledSteps([...initialDisabledSteps]);
    }
  };

  return (
    <Container className={classes.contentContainer}>
      <div className={classes.headingContainer}>
        <Text h2 color='ship-cove' className={classes.titleText}>
          {isRequestFormTranslationReady ? (
            requestFormTFunction('review.title')
          ) : (
            <Skeleton className={classes.titleSkeleton} />
          )}
        </Text>
        <Text paragraph color='ship-cove' className={classes.descriptionText}>
          {isRequestFormTranslationReady ? (
            requestFormTFunction('review.description')
          ) : (
            <Skeleton className={classes.descriptionSkeleton} />
          )}
        </Text>
      </div>
      <PageContainer className={classes.pageContainer}>
        {personalInformation && (
          <div className={classes.sectionContainer}>
            <Text h6 color='ship-cove'>
              {requestFormTFunction('review.memberInformation')}
            </Text>
            <Divider className={clsx(classes.divider)} />
            <Container isFlex className={clsx(classes.personalInformationTable)}>
              <LabeledText
                label={requestFormTFunction('personalInformation.name')}
                text={`${personalInformation?.first_name} ${personalInformation?.last_name}`}
              />
              <LabeledText
                label={requestFormTFunction('personalInformation.email')}
                text={personalInformation?.email}
              />
              <LabeledText label={requestFormTFunction('personalInformation.dob')} text={personalInformation?.dob} />
              <LabeledText
                label={requestFormTFunction('personalInformation.phoneNumber')}
                text={personalInformation?.phone_number}
              />
              <LabeledText
                label={requestFormTFunction('personalInformation.hcn')}
                text={personalInformation?.insurance_number}
              />
              <LabeledText
                label={requestFormTFunction('personalInformation.address')}
                text={
                  <>
                    <Text
                      fontWeight={500}
                      paragraph
                    >{`${personalInformation?.street_address} ${personalInformation?.extra_line_1}`}</Text>
                    <Text
                      fontWeight={500}
                      paragraph
                    >{`${personalInformation?.city}, ${personalInformation?.province}`}</Text>
                    <Text fontWeight={500} paragraph>{`${personalInformation?.postal_code}`}</Text>
                  </>
                }
              />
            </Container>
          </div>
        )}
        {institutionData?.map((provider, index) => {
          const { physician, institution, address } = provider;
          const physicianName =
            physician?.first_name && physician?.last_name && `${physician?.first_name} ${physician?.last_name}`;

          return (
            <div className={classes.sectionContainer} key={generateKey(institution.id, index)}>
              <Text h6 color='ship-cove'>
                {requestFormTFunction('review.institutionInformation')}
              </Text>
              <Divider className={clsx(classes.divider)} />
              <Container isFlex className={clsx(classes.personalInformationTable)}>
                <LabeledText
                  label={requestFormTFunction('physicianAndInstitutionPicker.institution')}
                  text={institution?.institution_name}
                />
                <LabeledText
                  label={requestFormTFunction('physicianAndInstitutionPicker.physicianInput.label')}
                  text={physicianName || requestFormTFunction('physicianAndInstitutionPicker.noPhysicianSelected')}
                />
                <LabeledText
                  label={requestFormTFunction('personalInformation.address')}
                  text={
                    <>
                      <Text fontWeight={500} paragraph>{`${address.address}`}</Text>
                      <Text fontWeight={500} paragraph>{`${address?.city}, ${address?.province}`}</Text>
                      <Text fontWeight={500} paragraph>{`${address?.postal_code}`}</Text>
                    </>
                  }
                />
                <LabeledText
                  label={requestFormTFunction('physicianAndInstitutionPicker.notes')}
                  text={provider?.notes || requestFormTFunction('physicianAndInstitutionPicker.noNotes')}
                />
              </Container>
            </div>
          );
        })}
        {memberUploads && memberUploads?.length > 0 && (
          <div className={classes.sectionContainer}>
            <Text h6 color='ship-cove'>
              {requestFormTFunction('uploadRecords.title')}
            </Text>
            <Divider className={clsx(classes.divider)} />
            <Text paragraph>{requestFormTFunction('review.memberUploadDisclaimer')}</Text>
            <ul className={classes.memberUploadList}>
              {memberUploads.map((file) => (
                <Text li color='grey-60'>
                  {file.name}
                </Text>
              ))}
            </ul>
          </div>
        )}
        <div className={classes.sectionContainer}>
          <Text h6 color='ship-cove'>
            {requestFormTFunction('authorization.title')}
          </Text>
          <Divider className={clsx(classes.divider)} />
          {signatureUrl ? (
            <>
              <div className={classes.imageContainer}>
                <img
                  className={classes.signatureImg}
                  src={signatureUrl}
                  alt={requestFormTFunction('review.signatureAltText')}
                />
              </div>
              <Text paragraph color='grey-60'>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Trans
                  t={requestFormTFunction}
                  i18nKey='authorization.authorizeReleaseDisclaimer'
                  components={{
                    tosLink: <Text a openInNewTab href={DOT_HEALTH_PRIVACY_POLICY_URL} />,
                    privacyLink: <Text a openInNewTab href={DOT_HEALTH_TERMS_URL} />
                  }}
                  values={{
                    institution: institution
                  }}
                />
              </Text>
            </>
          ) : (
            <Text paragraph color='grey-60'>
              {requestFormTFunction('review.noInstitutionSelected')}
            </Text>
          )}
        </div>
        {informationRequestError && (
          <Text paragraph bold align='center' color='error' style={{ marginBottom: '1.6rem' }}>
            {requestFormTFunction('errors.generic')}
          </Text>
        )}
        {memberUploadError && (
          <Text paragraph bold align='center' color='error' style={{ marginBottom: '1.6rem' }}>
            Something went wrong with uploading the files
          </Text>
        )}
        <Container className={classes.buttonContainer}>
          {isRequestFormTranslationReady ? (
            <Button
              style={{ minWidth: '24rem' }}
              isLoading={informationRequestLoading || memberUploadLoading}
              type='button'
              isDisabled={
                informationRequestLoading ||
                memberUploadLoading ||
                (isEmptyArray(institutionData) && isEmptyArray(memberUploads))
              }
              label={informationRequestLoading || memberUploadLoading ? '' : requestFormTFunction('submitRequest')}
              onClick={handleSubmitInformationRequest}
            />
          ) : (
            <Skeleton width='10rem' height='4.6rem' />
          )}
        </Container>
      </PageContainer>
    </Container>
  );
};

export default RequestSummary;
