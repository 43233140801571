import moment from 'moment';

// Update this whenever a new translation file is added / updated
// Adding a new file 1.0 > 2.0
// Updating a new file 1.0 > 1.0
// Ensure [version-resource-files] and [src/utils/constants.ts] have the same versions
export const TRANSLATION_FILE_VERSION = '2.0';

export const PARTNERED_INSTITUTIONS = {
  teladoc: 'Teladoc'
};

export const LOCALSTORAGE = {
  publicVerifyEmailToken: 'publicVerifyEmailToken',
  publicAccessToken: 'publicAccessToken',
  publicPortalAccessToken: 'publicPortalAccessToken'
};

export const MINIMUM_ACCEPTABLE_PASSWORD_STRENGTH_SCORE = 3;

export const DOT_HEALTH_PRIVACY_POLICY_URL = 'https://www.dothealth.ca/privacy-policy';
export const DOT_HEALTH_TERMS_URL = 'https://www.dothealth.ca/terms';
export const DOT_SUPPORT_MAIL = 'care@dothealth.ca';
export const DOT_SUPPORT_PHONE = '1(800) 348-0368';
export const DOT_SUPPORT_MAIL_LINK = `mailto:${DOT_SUPPORT_MAIL}`;
export const DOT_SUPPORT_PHONE_LINK = `tel:${DOT_SUPPORT_PHONE}`;
export const DOT_CONNECT_KNOWLEDGE_BASE_URL = 'http://help.dothealth.ca/en/collections/2791941-using-dot-connect';

export const MAX_FILE_SIZE = 20 * 1000 * 1000; // 20MB
export const MAX_FILE_COUNT = 10;
export const MIN_CLIENT_BIRTH_DATE = moment().subtract(18, 'years').toDate();
export const MAX_CLIENT_BIRTH_DATE = moment().subtract(120, 'years').toDate();
