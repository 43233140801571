import { makeStyles } from '@material-ui/core';
import Modal from './Modal';
import Input from './Input';
import { InstitutionAddress, Physician } from '../../types';
import { useTranslate } from '../../hooks/useTranslate';
import Container from './Container';
import Spinner from './Spinner';
import { SubmitFunction, useForm, ValidationErrors } from '../../hooks/useForm';
import Button from './Button';
import { useMutation } from '../../hooks/useQuery';
import Text from './Typography';
import { isValidEmail } from '../../utils';

const useStyles = makeStyles(() => ({
  modal: {
    maxWidth: '50rem'
  },
  formContainer: {
    padding: '2rem 0',
    width: '100%',
    maxWidth: '50rem'
  },
  sectionContainer: {
    minHeight: '4.6rem',
    width: '100%',
    padding: '0 2.7rem',
    marginBottom: '3rem'
  }
}));

const INITIAL_VALUES = {
  first_name: '',
  last_name: '',
  email: ''
};

export type CreatePhysicianModalInitialValues = typeof INITIAL_VALUES;

export interface CreatePhysicianModalProps {
  institutionAddress: InstitutionAddress;
  open: boolean;
  onClose: () => void;
  setPhysician: (physician: Physician) => any;
  address_id: number;
}

export interface CreatePhysicianInstitution {
  physician: {
    address_ids?: [number];
    institution_ids?: [number];
    first_name: string;
    last_name: string;
    city: string;
    province: string;
    country: string;
    address: string;
    institution_name: string;
  };
}

const CreatePhysicianModal = ({
  open,
  onClose,
  setPhysician,
  address_id,
  institutionAddress
}: CreatePhysicianModalProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslate('createNewPhysicianModal');
  const { t: errorsT } = useTranslate('formErrors');

  const {
    loading: createPhysicianLoading,
    makeRequest,
    error: createPhysicianError
  } = useMutation<CreatePhysicianInstitution>('POST');

  const { handleSubmit, resetForm, getFieldProps } = useForm(INITIAL_VALUES, {
    validate: (values) => {
      const errors: ValidationErrors<typeof INITIAL_VALUES> = {};

      if (!values.first_name) {
        errors.first_name = errorsT('requiredField');
      }
      if (!values.last_name) {
        errors.last_name = errorsT('requiredField');
      }
      if (!values.email || !isValidEmail(values.email)) {
        errors.email = errorsT('invalidEmail');
      }
      return errors;
    }
  });

  const onSubmit: SubmitFunction<CreatePhysicianModalInitialValues> = (values) => {
    const data = {
      physician: {
        address_ids: [address_id],
        institution_ids: [institutionAddress.institution_id],
        first_name: values.first_name,
        last_name: values.last_name,
        city: institutionAddress.city,
        province: institutionAddress.province,
        country: '',
        address: institutionAddress.address,
        institution_name: institutionAddress.name
      }
    };
    makeRequest(`/physicians`, data as CreatePhysicianInstitution)
      .then((resp) => {
        resetForm();
        setPhysician(resp.physician);
      })
      .catch((err) => console.error(err));
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      header={ready && t('heading')}
      showHeaderDivider={ready}
      className={classes.modal}
    >
      {!ready ? (
        <Container height='10rem' width='10rem' justifyContent='center'>
          <Spinner />
        </Container>
      ) : (
        <form className={classes.formContainer} onSubmit={handleSubmit(onSubmit)}>
          <Container className={classes.sectionContainer}>
            <Input
              maxWidth
              label={t('inputs.firstName')}
              {...getFieldProps('first_name')}
              type='text'
              required
              focusOnMount
              noPlaceholders
            />
            <Input
              maxWidth
              label={t('inputs.lastName')}
              {...getFieldProps('last_name')}
              type='text'
              autoComplete='off'
              required
              noPlaceholders
            />
            <Input
              maxWidth
              label={t('inputs.email')}
              {...getFieldProps('email')}
              type='email'
              autoComplete='off'
              noPlaceholders
            />
          </Container>
          {createPhysicianError && (
            <Text paragraph bold align='center' color='error' style={{ marginBottom: '1.6rem' }}>
              {createPhysicianError}
            </Text>
          )}
          <Container justifyContent='center'>
            <Button
              style={{ minWidth: '24rem' }}
              isLoading={createPhysicianLoading}
              type='submit'
              label={createPhysicianLoading ? '' : t('addNewPhysicianButtonText')}
            />
          </Container>
        </form>
      )}
    </Modal>
  );
};

export default CreatePhysicianModal;
